<app-header class="hide"></app-header>

<div class="banner-img-name"></div>

<div class="center_body">

    <form action="" method="post" name="paymentSuccessForm" #paymentSuccessForm="ngForm" (ngSubmit)="paymentSuccessForm.form.valid" autocomplete="off" id="msform1">
    
        <input type="hidden" name="applicationId" value="{{paymentDetailsObject.applicationId}}">
        <input type="hidden" name="txnReference" value="{{paymentDetailsObject.txnRefNumber}}">
        <input type="hidden" name="downloadType" value="">

        <div class="card">
    
            <div style="border-radius:200px; height:100px; width:100px; background: #e4f4cc; margin:0 auto; border:1px solid #f0f0f0">
                <i class="checkmark">✓</i>
            </div>

            <h1>Payment Successful!</h1>

            <div class="info_paid">
                <span><b>Transaction Ref:</b> {{paymentDetailsObject.txnRefNumber}}</span>
                <span><b>{{paymentDetailsObject.referenceLabel}} : </b> {{paymentDetailsObject.referenceValue}}</span>
                <span *ngIf="paymentDetailsObject.paymentType === constant.COD_PAYMENT"><b>Passcode : </b> {{paymentDetailsObject.passcode}}</span>
                <span><b>Total Amount:</b> {{paymentDetailsObject.currencySymbol}} {{paymentDetailsObject.totalAmount | number : '1.2' : 'en-US'}}</span>
            </div>

            <h6>Paid Application Details</h6>

            <table style="width: 100%;" class="pay_ment">
                <thead>
                    <tr>
                        <th width="19%" nowrap>Application No.</th>
                        <th width="39%" nowrap>Full Name</th>
                        <th width="20%" nowrap>Passport Type</th>
                        <th width="14%" nowrap>Fee</th>
                        <th width="8%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Application ID" width="19%" style="border-left: 0;">{{paymentDetailsObject.applicationId}}</td>
                        <td data-label="Full Name" width="39%">{{paymentDetailsObject.fullName}}</td>
                        <td data-label="Passport Type " width="20%">{{paymentDetailsObject.passportType}}</td>
                        <td data-label="Fee" width="14%" style="text-align:right;">{{paymentDetailsObject.currencySymbol}} {{paymentDetailsObject.totalAmount | number : '1.2' : 'en-US'}}</td>
                        <td data-label="Action" width="8%"><span tooltip="View" placement="bottom" (click)="viewApplication()" style="margin-left:20px; cursor: pointer;"><i class="fa fa-eye" aria-hidden="true"></i></span></td>
                    </tr>
                </tbody>
            </table>

            <ng-container *ngIf="paymentDetailsObject.paymentType === constant.COD_PAYMENT">
                <input type="button" (click)="printApplication()" value="Print" class="edit_btn hide" style="margin-right: 5px;">
            </ng-container>

            <input type="button" (click)="downloadPDF(constant.APPLICATION_DOWNLOAD)" value="Download Application" class="btn hide" style="margin-right: 5px;">
            
            <input type="button" (click)="downloadPDF(constant.RECEIPT_DOWNLOAD)" value="Download Receipt" class="save_btn hide" style="margin-right: 5px;">
            
            <ng-container *ngIf="paymentDetailsObject.isPremium === constant.FALSE && paymentDetailsObject.paymentType === constant.APPLICATION_PAYMENT">
                <input type="button" (click)="bookAppointment()" value="Book Appointment" class="edit_btn">
                <div class="book-appointment-note">
                    <p><b>Note:</b>You are required to book an appointment before going for biometric capture. <br/>Click on Book Appointment to book your slot.</p>
                </div>
            </ng-container>

            <ng-container *ngIf="paymentDetailsObject.isPremium === constant.TRUE && paymentDetailsObject.paymentType === constant.APPLICATION_PAYMENT">
                <input type="button" (click)="openPremiumApplication()" value="Book Premium Appointment" class="edit_btn">
                <div class="book-appointment-note">
                    <p><b>Note: </b>Click on <b>Book Premium Appointment</b> button to complete your Passport application</p>
                </div>
            </ng-container>

        </div>
        
    </form>
    
</div>

<app-footer class="hide"></app-footer>
