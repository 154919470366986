import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { SelectService } from '../common/select-service';
import { Utils } from '../common/utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { AbstractControl, NgForm } from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { Title } from '@angular/platform-browser';
import { InformationModalComponent } from '../information-modal/information-modal.component';
import { GUI } from '../common/gui';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { PhotoGuidelinesModalComponent } from '../photo-guidelines-modal/photo-guidelines-modal.component';
import { ImageUploadModalComponent } from '../image-upload-modal/image-upload-modal.component';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';
import { environment } from 'src/environments/environment';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Pattern } from '../common/pattern';

@Component({
  selector: 'app-application-add-edit',
  templateUrl: './application-add-edit.component.html',
  styleUrls: ['./application-add-edit.component.css']
})
export class ApplicationAddEditComponent {

  @ViewChild('passportTypeForm') passportTypeForm: NgForm;

  @ViewChild('uploadPhotoForm') uploadPhotoForm: NgForm;

  @ViewChild('personalDetailsForm') personalDetailsForm: NgForm;

  @ViewChild('contactInformationForm') contactInformationForm: NgForm;

  @ViewChild('kinInformationForm') kinInformationForm: NgForm;

  @ViewChild('supportingDocumentsForm') supportingDocumentsForm: NgForm;

  @ViewChild('passportDeliveryForm') passportDeliveryForm: NgForm;


  private masterEncryptedApplicantId: string = this.constants.BLANK;

  private applicantId: string = this.constants.BLANK;

  applicationStage: number = this.constants.PROCESSING_INFO_STAGE;

  applicationHighestStage: number = this.constants.PROCESSING_INFO_STAGE;

  menuClickedStage: number = this.constants.ANY;

  private shallExitApplication: number;


  passportTypeFormObject: any = {};

  feeDetailsObject: any = {};

  uploadPhotoFormObject: any = {};

  personalDetailsFormObject: any = {};

  contactInformationFormObject: any = {};

  kinInformationFormObject: any = {};

  supportingDocumentsFormObject: any = {};

  passportDeliveryFormObject: any = {};

  deliveryFeeDetailsObject: any = {};


  changeTypeSelectService = new SelectService();

  processingOfficeSelectService = new SelectService();

  stateSelectService = new SelectService();

  // bookletSelectService = new SelectService();

  // validitySelectService = new SelectService();

  allStateSelectService = new SelectService();

  deliveryStateSelectService = new SelectService();

  contactInformationFormLGASelectService = new SelectService();

  currentAddressLGASelectService = new SelectService();

  kinInformationFormLGASelectService = new SelectService();

  passportDeliveryFormLGASelectService = new SelectService();


  private defaultSelectOption = { value: this.constants.BLANK, text: this.constants.DEFAULT_SELECT_OPTION_LABEL };

  private nisHQStateSelectOption = { value: this.constants.FCT_HQ_OFFICE_ID, text: this.constants.FCT_HQ_OFFICE_LABEL };


  bookletSelectOptionsList: any = [this.defaultSelectOption];

  validitySelectOptionsList: any = [this.defaultSelectOption];

  natureOfCitizenshipSelectOptionsList: any = [];

  serverValidityList: any = [];

  monthsList: any = [];

  applicationStepsList: any[] = [];

  titleList: any[] = [this.defaultSelectOption];

  applicableDocsList: any[] = [];


  ng2TelInputOptions = { initialCountry: 'ng', preferredCountries: [], separateDialCode: true, autoPlaceholder: '', allowDropdown: false, formatOnDisplay: false };

  ng2TelInputOptionsWithDropdown = { initialCountry: 'ng', preferredCountries: [], separateDialCode: true, autoPlaceholder: '', allowDropdown: true, formatOnDisplay: false };


  isDisablePassportFormSubmitButton: boolean = false;

  isShowFeesSection: boolean = false;

  isShowDeliveryFeesSection: boolean = false;

  imageUploaded: boolean = false;

  imageValidatedButtonClicked: boolean = false;

  imageValidated: boolean = false;

  disableProcessingStateInputField: boolean = false;

  enablePassportDeliveryStage: boolean = environment.enablePassportDeliveryStage;

  passportDeliveryTermsLink: string = environment.passportDeliveryTermsLink;


  changeTypeLabel: string = 'Reason for Renewal';

  allowImagesList: string = this.constants.ALLOW_IMAGE_LIST;

  refrenceNumber: string = this.constants.BLANK;

  appointmentAvailabilityURL: string = this.constants.BLANK;

  stateOfOriginValue: string = this.constants.BLANK;

  passportTypeLabel: string = this.constants.BLANK;

  isDeliveryAvailable: number = this.constants.TRUE;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils, public constants: Constants, private loaderService: LoaderService,
    private simpleModalService: SimpleModalService, private title: Title, public gui: GUI, public pattern: Pattern) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.masterEncryptedApplicantId = params['applicantId'];
    });
    this.applicantId = this.utils.decryptString(this.masterEncryptedApplicantId);
    this.title.setTitle('Passport Application | Nigerian Immigration Services');
    this.utils.storeApplicationSteps(this.applicationStepsList);
    this.monthsList = this.utils.getMonthsArray();
    this.storeStateList();
    this.passportTypeFormObject.applicationType = this.constants.PASSPORT_APPLICATION;
    this.storeApplicationData(this.applicantId);

  }

  private storeStateList(): void {
    this.httpUtils.fetchCountryStateList().then(serverResponse => {
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        const serverCountryStateList = [this.defaultSelectOption];
        for (const valueJSONObject of serverResponse.states) {
          serverCountryStateList.push({ value: valueJSONObject.id, text: valueJSONObject.stateName });
        }
        this.stateSelectService.setSelectOptions(serverCountryStateList);

        const serverCountryAllStateList = [this.defaultSelectOption];
        for (const valueJSONObject of serverResponse.allStates) {
          serverCountryAllStateList.push({ value: valueJSONObject.id, text: valueJSONObject.stateName });
        }
        this.allStateSelectService.setSelectOptions(serverCountryAllStateList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }


  private storeContactlessStatus(value): void {
    localStorage.setItem("contactlessStatus", value);
    this.passportTypeFormObject.contactlessStatus = value;

  }


  get isBiometricEnrollmentRequired(): boolean {
    return ['4.00000', '20.00000', '14.00000'].includes(this.passportTypeFormObject?.processingState);
  }



  private fetchCurrentForm(): NgForm {
    if (this.passportTypeForm !== undefined) {
      return this.passportTypeForm;
    }

    if (this.uploadPhotoForm !== undefined) {
      return this.uploadPhotoForm;
    }

    if (this.personalDetailsForm !== undefined) {
      return this.personalDetailsForm;
    }

    if (this.contactInformationForm !== undefined) {
      return this.contactInformationForm;
    }

    if (this.kinInformationForm !== undefined) {
      return this.kinInformationForm;
    }

    if (this.supportingDocumentsForm !== undefined) {
      return this.supportingDocumentsForm;
    }

    if (this.passportDeliveryForm !== undefined) {
      return this.passportDeliveryForm;
    }

    return null;
  }

  openFieldSet(stage: number, event: any): void {
    window.scrollTo(0, 0);
    const currentForm = this.fetchCurrentForm();
    if (currentForm === null) {
      return;
    }

    currentForm.onSubmit(event);
    if (currentForm.valid === true) {
      const hasFieldValueChangedControl = currentForm.form.controls['hasFieldValueChanged'];
      if (hasFieldValueChangedControl !== undefined && hasFieldValueChangedControl.value === this.constants.TRUE) {
        const callableFunctionName = currentForm.form.value.callableFunctionName;
        this.showDataChangeInformationModal(event, hasFieldValueChangedControl, callableFunctionName, stage);
      } else {
        this.applicationStage = stage;
      }
    }
  }

  private showDataChangeInformationModal(event: any, hasFieldValueChangedControl: AbstractControl, callableFunctionName: string, stage: number) {
    const message = "It seems you have changed some content on this page. <br> Would you like to save the data first?";
    const modalParamsObject = { title: "Confirmation", message: message, confirmButtonLabel: "YES", cancelButtonLabel: "NO", isShowCancelButton: true };
    this.simpleModalService.addModal(ConfirmationModalComponent, modalParamsObject, { autoFocus: true }).subscribe((isConfirmed: boolean) => {
      if (isConfirmed === true) {
        hasFieldValueChangedControl.setValue(this.constants.FALSE);
        event.target.name = "confirmation";
        this.menuClickedStage = stage;
        this[callableFunctionName](event);
      }
    });
  }

  openPreviousFieldSet(): void {
    this.applicationStage--;
  }

  private setDateOfBirth(): void {
    const dateOfBirthArray = this.personalDetailsFormObject.dateOfBirth.split('/');
    this.personalDetailsFormObject.dateOfBirthDay = dateOfBirthArray[0];
    this.personalDetailsFormObject.dateOfBirthMonth = dateOfBirthArray[1];
    this.personalDetailsFormObject.dateOfBirthYear = dateOfBirthArray[2];
  }

  setFieldsToBlank(formObject: {}, fieldsArray: any[], selectServiceArray: SelectService[], selectArrayList: any[], isFeeObjectEffected: boolean): void {
    for (let i = 0; i < fieldsArray.length; i++) {
      formObject[fieldsArray[i]] = this.constants.BLANK;
    }

    if (selectServiceArray !== null) {
      for (const selectService of selectServiceArray) {
        selectService.setSelectOptions([this.defaultSelectOption]);
      }
    }

    if (selectArrayList !== null) {
      for (let i = 0; i < selectArrayList.length; i++) {
        let arrayName = selectArrayList[i];
        arrayName.splice(0, arrayName.length);
        arrayName.push(this.defaultSelectOption);
      }
    }

    if (isFeeObjectEffected === true) {
      this.isShowFeesSection = false;
      this.feeDetailsObject = {};
    }
  }

  private setPassportTypeFormDefaultValues(): void {
    const fieldsArray = ['processingState', 'processingOffice'];
    const selectServiceArray = [this.processingOfficeSelectService];
    this.setFieldsToBlank(this.passportTypeFormObject, fieldsArray, selectServiceArray, [], false);
  }

  private setContactInformationFormDefaultValues(): void {
    const fieldsArray = ['contactState', 'addressState', 'addressLGA', 'currentAddressState', 'currentAddressLGA'];
    const selectServiceArray = [this.contactInformationFormLGASelectService, this.currentAddressLGASelectService];
    this.setFieldsToBlank(this.contactInformationFormObject, fieldsArray, selectServiceArray, [], false);
    this.contactInformationFormObject.isCurrentAddressSame = false;
  }

  private setKinInformationFormDefaultValues(): void {
    const fieldsArray = ['kinRelation', 'addressState', 'addressLGA'];
    const selectServiceArray = [this.kinInformationFormLGASelectService];
    this.setFieldsToBlank(this.kinInformationFormObject, fieldsArray, selectServiceArray, [], false);
  }

  private isValidDetails(formObject: any = {}, checkedFieldsArray: any = []): boolean {
    let fieldBlankCount = 0;

    for (let i = 0; i < checkedFieldsArray.length; i++) {
      if (this.utils.isEmpty(formObject[checkedFieldsArray[i]]) === true) {
        fieldBlankCount++;
      }
    }

    return fieldBlankCount > 0 ? false : true;
  }

  private storeApplicationData(applicantId: string): void {
    this.loaderService.startLoader();
    this.httpUtils.fetchV2ApplicationData(applicantId).then(serverResponse => {
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.setupUploadPhotoFormObject(serverResponse);

        this.setupPersonalDetailsFormObject(serverResponse);

        this.setupPassportTypeFormObject(serverResponse);

        this.setupFeeDetailsObject(serverResponse);

        this.setupContactInformationFormObject(serverResponse);

        this.setupKinInformationFormObject(serverResponse);

        this.setupPassportDeliveryFormObject(serverResponse);

        this.setupDeliveryFeeDetailsObject(serverResponse);

        this.setCallableFunction();

        this.refrenceNumber = serverResponse.personalInfo.referenceNumber;
        this.applicationStage = serverResponse.applicationStage;
        this.applicationHighestStage = serverResponse.applicationStage;

        if (this.applicationStage === this.constants.APPLICANT_INFO_STAGE) {
          this.refrenceNumberInfoModal();
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private setupUploadPhotoFormObject(serverResponse: any): void {
    if (serverResponse.uploadPhotoDetails !== null) {
      this.uploadPhotoFormObject = serverResponse.uploadPhotoDetails;
      this.imageValidated = this.utils.isEmpty(this.uploadPhotoFormObject.validatedBase64Data) === false;
    }
  }

  private setupPersonalDetailsFormObject(serverResponse: any): void {
    this.personalDetailsFormObject = serverResponse.personalInfo;
    this.stateOfOriginValue = this.personalDetailsFormObject.stateOfOrigin;
    for (let titleObject of this.personalDetailsFormObject.titleList) {
      this.titleList.push({ value: titleObject.key, text: titleObject.value });
    }

    this.setDateOfBirth();
    this.storeApplicableDocs();
  }

  private setupPassportTypeFormObject(serverResponse: any): void {
    this.passportTypeFormObject = serverResponse.passportDetails;
    this.passportTypeLabel = this.utils.fetchPassportTypeLabel(this.passportTypeFormObject.passportType);
    this.changeTypeLabel = this.passportTypeFormObject.passportType === this.constants.STANDARD_PASSPORT_COD ? 'Current Passport Status' : 'Reason for Renewal';
    this.setAppointmentAvailabilityURL(this.passportTypeFormObject.processingOfficeName);

    this.natureOfCitizenshipSelectOptionsList = this.utils.getNatureOfCitizenshipList();
    this.isDeliveryAvailable = this.passportTypeFormObject.isDeliveryAvailable;

    if (this.passportTypeFormObject.applyingFor !== this.constants.FRESH) {
      this.utils.storeChangeTypes(this.changeTypeSelectService, this.passportTypeFormObject.applyingFor);
    }

    this.fetchBookletList(false);

    this.fetchValidityList(false);

    this.disableProcessingStatesAsPerReasons(false);

    if (this.utils.isEmpty(this.passportTypeFormObject.processingState) === false) {
      this.storeStateProcessingOfficeList(false);
    } else {
      this.setPassportTypeFormDefaultValues();
    }
  }

  private setupFeeDetailsObject(serverResponse: any): void {
    this.feeDetailsObject = serverResponse.feeDetails;
    if (this.isValidDetails(this.passportTypeFormObject, ['processingState', 'processingOffice', 'bookletType', 'passportValidity']) === true) {
      this.isShowFeesSection = true;
    }
  }

  private setupDeliveryFeeDetailsObject(serverResponse: any): void {
    this.deliveryFeeDetailsObject = serverResponse.deliveryFeeDetails;
    if (this.isValidDetails(this.passportDeliveryFormObject, ['receiverName', 'emailId', 'mobileNumber', 'receiverAddressLine1', 'receiverAddressState', 'receiverAddressLga', 'receiverAddressLandmark']) === true) {
      this.isShowDeliveryFeesSection = true;
    }
  }

  private setupContactInformationFormObject(serverResponse: any): void {
    if (serverResponse.contactInfo !== null) {
      this.contactInformationFormObject = serverResponse.contactInfo;
      this.stateOfOriginValue = this.contactInformationFormObject.contactState;
      this.fetchStateLGA(this.contactInformationFormObject, 'addressState', this.contactInformationFormLGASelectService, 'addressLGA', false);
      this.fetchStateLGA(this.contactInformationFormObject, 'currentAddressState', this.currentAddressLGASelectService, 'currentAddressLGA', false);
      if (this.utils.isEmpty(this.contactInformationFormObject.phoneNumberCountryISOCode) === false) {
        this.ng2TelInputOptionsWithDropdown.initialCountry = this.contactInformationFormObject.phoneNumberCountryISOCode;
      }
    } else {
      this.setContactInformationFormDefaultValues();
    }
  }

  private setupKinInformationFormObject(serverResponse: any): void {
    if (serverResponse.kinInformation !== null) {
      this.kinInformationFormObject = serverResponse.kinInformation;
      this.fetchStateLGA(this.kinInformationFormObject, 'addressState', this.kinInformationFormLGASelectService, 'addressLGA', false);
    } else {
      this.setKinInformationFormDefaultValues();
    }
  }

  private setupPassportDeliveryFormObject(serverResponse: any): void {
    this.passportDeliveryFormObject = serverResponse.deliveryInfo;
    this.fetchStateLGA(this.passportDeliveryFormObject, 'receiverAddressState', this.passportDeliveryFormLGASelectService, 'receiverAddressLga', false);
    if (this.passportDeliveryFormObject.deliveryOpted === this.constants.ANY) {
      this.passportDeliveryFormObject.deliveryOpted = this.isDeliveryAvailable === this.constants.TRUE ? this.constants.BLANK : this.constants.FALSE;
    }
  }

  private setCallableFunction(): void {
    this.passportTypeFormObject.callableFunctionName = "openImageUploadForm";

    this.uploadPhotoFormObject.callableFunctionName = "openPersonalDetailsForm";

    this.personalDetailsFormObject.callableFunctionName = "openContactInformationForm";

    this.contactInformationFormObject.callableFunctionName = "openKinInformationForm";

    this.kinInformationFormObject.callableFunctionName = "openSupportingDocumentsForm";

    this.supportingDocumentsFormObject.callableFunctionName = "openPassportDelivryForm";

    this.passportDeliveryFormObject.callableFunctionName = "openApplicationPreview";
  }

  disableProcessingStatesAsPerReasons(isChangedFromGUI: boolean): void {
    if (isChangedFromGUI === true) {
      this.loaderService.startLoader();
      this.setFieldsToBlank(this.passportTypeFormObject, ['processingState', 'processingOffice', 'bookletType', 'passportValidity'], [this.processingOfficeSelectService], [this.bookletSelectOptionsList, this.validitySelectOptionsList], true);
    }

    if (this.passportTypeFormObject.natureOfCitizenship === this.constants.NATURALIZATION) {
      this.passportTypeFormObject.processingState = this.constants.FCT_STATE_ID;

      this.processingOfficeSelectService.setSelectOptions([this.nisHQStateSelectOption]);
      this.passportTypeFormObject.processingOffice = this.constants.FCT_HQ_OFFICE_ID;

      this.disableProcessingStateInputField = true;
      this.setAppointmentAvailabilityURL(this.constants.FCT_HQ_OFFICE_LABEL);
      if (isChangedFromGUI === true) {
        this.fetchBookletList(true);
      }
    } else {
      this.disableProcessingStateInputField = false;
    }

    if (isChangedFromGUI === true) {
      this.loaderService.stopLoader();
    }
  }

  storeStateProcessingOfficeList(isChangedFromGUI: boolean): void {
    if (isChangedFromGUI === true) {
      this.loaderService.startLoader();
      this.setFieldsToBlank(this.passportTypeFormObject, ['processingOffice', 'bookletType', 'passportValidity'], [this.processingOfficeSelectService], [this.bookletSelectOptionsList, this.validitySelectOptionsList], true);
    }

    if (this.isValidDetails(this.passportTypeFormObject, ['processingState']) === false) {
      this.processingOfficeSelectService.setSelectOptions([this.defaultSelectOption]);
      if (isChangedFromGUI === true) {
        this.loaderService.stopLoader();
      }
      return;
    }

    this.httpUtils.fetchStateProcessingOfficeList(this.passportTypeFormObject.processingState).then(serverResponse => {
      if (isChangedFromGUI === true) {
        this.loaderService.stopLoader();
      }
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        const serverStateProcessingOfficeList = [{ value: this.constants.BLANK, text: this.constants.DEFAULT_SELECT_OPTION_LABEL, isPremium: this.constants.FALSE, name2: this.constants.BLANK }];
        for (const valueJSONObject of serverResponse.passportOffices) {
          serverStateProcessingOfficeList.push({ value: valueJSONObject.id, text: valueJSONObject.name, isPremium: valueJSONObject.isPremium, name2: valueJSONObject.name2 });
        }
        this.processingOfficeSelectService.setSelectOptions(serverStateProcessingOfficeList);
        if (isChangedFromGUI === true) {
          this.isDeliveryAvailable = serverResponse.isDeliveryAvailable;
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchBookletList(isChangedFromGUI: boolean): void {
    if (isChangedFromGUI === true) {
      this.loaderService.startLoader();
      this.setFieldsToBlank(this.passportTypeFormObject, ['bookletType', 'passportValidity'], [], [this.bookletSelectOptionsList, this.validitySelectOptionsList], true);
    }

    if (this.utils.isEmpty(this.passportTypeFormObject.processingOffice) === true) {
      this.setFieldsToBlank(this.passportTypeFormObject, ['bookletType', 'passportValidity'], [], [this.bookletSelectOptionsList, this.validitySelectOptionsList], true);
      if (isChangedFromGUI === true) {
        this.loaderService.stopLoader();
      }
      return;
    }

    this.loaderService.startLoader();
    this.passportTypeFormObject.applicantId = this.applicantId;
    this.httpUtils.fetchBookletList(this.passportTypeFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.storeBookletList(serverResponse.bookletList);
      } else {
        this.feeDetailsObject = {};
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private storeBookletList(bookletList: any): void {
    this.bookletSelectOptionsList = [this.defaultSelectOption];
    for (const valueJSONObject of bookletList) {
      this.bookletSelectOptionsList.push({ value: valueJSONObject.key, text: valueJSONObject.value });
    }
  }

  fetchValidityList(isChangedFromGUI: boolean): void {
    if (isChangedFromGUI === true) {
      this.loaderService.startLoader();
      this.setFieldsToBlank(this.passportTypeFormObject, ['passportValidity'], [], [this.validitySelectOptionsList], true);
    }

    if (this.utils.isEmpty(this.passportTypeFormObject.bookletType) === true) {
      this.setFieldsToBlank(this.passportTypeFormObject, ['passportValidity'], [], [this.validitySelectOptionsList], true);
      if (isChangedFromGUI === true) {
        this.loaderService.stopLoader();
      }
      return;
    }

    this.passportTypeFormObject.applicantId = this.applicantId;
    this.httpUtils.fetchValidityList(this.passportTypeFormObject).then(serverResponse => {
      if (isChangedFromGUI === true) {
        this.loaderService.stopLoader();
      }
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.validitySelectOptionsList = [this.defaultSelectOption];
        for (const valueJSONObject of serverResponse.validityList) {
          this.validitySelectOptionsList.push({ value: valueJSONObject.key, text: valueJSONObject.value });
        }

        if (serverResponse.validityList.length === this.constants.TRUE) {
          this.passportTypeFormObject.passportValidity = this.validitySelectOptionsList[1].value;
          this.fetchPassportFeeDetails();
        }

      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchPassportFeeDetails(): void {
    this.isShowFeesSection = false;
    if (this.isValidDetails(this.passportTypeFormObject, ['processingState', 'processingOffice', 'bookletType', 'passportValidity']) === false) {
      return;
    }

    this.loaderService.startLoader();
    this.isDisablePassportFormSubmitButton = true;
    this.passportTypeFormObject.applicantId = this.applicantId;
    this.httpUtils.fetchPassportFeeDetails(this.passportTypeFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.feeDetailsObject = serverResponse;
        this.deliveryFeeDetailsObject = serverResponse;
        this.isShowFeesSection = true;
        this.isDisablePassportFormSubmitButton = this.feeDetailsObject.bookletFee <= this.constants.FALSE;
      } else {
        this.feeDetailsObject = {};
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchPassportDeliveryFeeDetails(): void {
    this.isShowDeliveryFeesSection = false;
    if (this.isValidDetails(this.passportDeliveryFormObject, ['receiverName', 'emailId', 'mobileNumber', 'receiverAddressLine1', 'receiverAddressState']) === false) {
      return;
    }

    this.loaderService.startLoader();
    this.passportDeliveryFormObject.applicantId = this.applicantId;
    this.httpUtils.fetchPassportDeliveryFeeDetails(this.passportDeliveryFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.feeDetailsObject = serverResponse;
        this.deliveryFeeDetailsObject = serverResponse;
        this.isShowDeliveryFeesSection = true;
      } else {
        this.deliveryFeeDetailsObject = {};
        this.isShowDeliveryFeesSection = false;
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchStateLGA(formObject: {}, fieldName: string, selectService: SelectService, childFieldName: string, isChangedFromGUI: boolean): void {
    const stateId = formObject[fieldName];
    if (this.utils.isEmpty(stateId) === true) {
      selectService.setSelectOptions([this.defaultSelectOption]);
      formObject[childFieldName] = this.constants.BLANK;
      return;
    }

    if (isChangedFromGUI === true) {
      this.loaderService.startLoader();
      selectService.setSelectOptions([this.defaultSelectOption]);
      formObject[childFieldName] = this.constants.BLANK;
    }

    this.httpUtils.fetchStateLGAList(stateId).then(serverResponse => {
      if (isChangedFromGUI === true) {
        this.loaderService.stopLoader();
      }
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        const serverStateLGAList = [this.defaultSelectOption];
        for (const valueJSONObject of serverResponse.lga) {
          serverStateLGAList.push({ value: valueJSONObject.id, text: valueJSONObject.description });
        }
        selectService.setSelectOptions(serverStateLGAList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private openInformationModal(): void {
    const message = 'REFERENCE NUMBER <b>' + this.personalDetailsFormObject.referenceNumber + '</b> (IMPORTANT - PLEASE WRITE DOWN THE REFERENCE NUMBER. YOU MAY REQUIRE IT LATER)';
    this.simpleModalService.addModal(InformationModalComponent, { message: message, isShowCheckBox: false, checkBoxLabel: this.constants.BLANK }, { autoFocus: true }).subscribe((isConfirmed: boolean) => {
      if (isConfirmed === true) {
        this.gui.openHomePage();
      }
    });
  }

  setCurrentAsPermanentAddress(isFinalSave: boolean): void {
    if (isFinalSave == false && this.contactInformationFormObject.isCurrentAddressSame === false) {
      this.contactInformationFormObject.currentAddressLine1 = this.constants.BLANK;
      this.contactInformationFormObject.currentAddressLine2 = this.constants.BLANK;
      this.contactInformationFormObject.currentAddressCity = this.constants.BLANK;
      this.contactInformationFormObject.currentAddressState = this.constants.BLANK;
      this.currentAddressLGASelectService.setSelectOptions([this.defaultSelectOption]);
      this.contactInformationFormObject.currentAddressLGA = this.constants.BLANK;
      this.contactInformationFormObject.currentAddressPostalCode = this.constants.BLANK;
      return;
    }

    if (this.contactInformationFormObject.isCurrentAddressSame === true) {
      this.contactInformationFormObject.currentAddressLine1 = this.contactInformationFormObject.addressLine1;
      this.contactInformationFormObject.currentAddressLine2 = this.contactInformationFormObject.addressLine2;
      this.contactInformationFormObject.currentAddressCity = this.contactInformationFormObject.addressCity;
      this.contactInformationFormObject.currentAddressState = this.contactInformationFormObject.addressState;
      this.fetchStateLGA(this.contactInformationFormObject, 'currentAddressState', this.currentAddressLGASelectService, 'currentAddressLGA', false);
      this.contactInformationFormObject.currentAddressLGA = this.contactInformationFormObject.addressLGA;
      this.contactInformationFormObject.currentAddressPostalCode = this.contactInformationFormObject.addressPostalCode;
    }
  }

  openPhotoGuidelineModal(): void {
    this.loaderService.startLoader();
    this.httpUtils.sendGuidelineMonitor(this.refrenceNumber).then(serverResponse => {
      this.loaderService.stopLoader();
      this.simpleModalService.addModal(PhotoGuidelinesModalComponent, null, { autoFocus: true });
    });

  }

  openImageUploadForm(event: any): void {
    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }
    window.scrollTo(0, 0);
    this.loaderService.startLoader();
    this.passportTypeFormObject.applicantId = this.applicantId;
    // const contactlessStatus = JSON.parse(localStorage.getItem("contactlessStatus"));
    // this.passportTypeFormObject.contactlessStatus;

    this.httpUtils.saveV2ProcessingInfoData(this.passportTypeFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.passportTypeFormObject.processingInfoId = serverResponse.processingInfoId;
        this.markFieldValueUnchanged(event, this.passportTypeFormObject);
        if (this.shallExitApplication === this.constants.TRUE) {
          this.openInformationModal();
          return;
        }

        if (this.isDeliveryAvailable === this.constants.FALSE) {
          this.passportDeliveryFormObject.deliveryOpted = this.constants.FALSE;
        } else if (this.passportDeliveryFormObject.deliveryOpted === this.constants.FALSE) {
          this.passportDeliveryFormObject.deliveryOpted = this.constants.BLANK;
        }

        this.storeApplicableDocs();

        if (this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.applicationStage = this.constants.UPLOAD_PHOTO_STAGE;
        this.applicationHighestStage = this.constants.UPLOAD_PHOTO_STAGE;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openPersonalDetailsForm(event: any): void {
    if (this.imageValidated === false) {
      return;
    }

    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }

    window.scrollTo(0, 0);

    if (this.shallExitApplication === this.constants.TRUE) {
      this.openInformationModal();
      return;
    }

    if (this.menuClickedStage > this.constants.ANY) {
      this.applicationStage = this.menuClickedStage;
      return;
    }


    this.loaderService.startLoader();
    this.applicationStage = this.constants.APPLICANT_INFO_STAGE;
    this.applicationHighestStage = this.constants.APPLICANT_INFO_STAGE;
    this.loaderService.stopLoader();

    this.refrenceNumberInfoModal();
  }

  openContactInformationForm(event: any): void {
    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }

    window.scrollTo(0, 0);
    this.loaderService.startLoader();
    this.personalDetailsFormObject.dateOfBirth = this.personalDetailsFormObject.dateOfBirthDay + '/' + this.personalDetailsFormObject.dateOfBirthMonth + '/' + this.personalDetailsFormObject.dateOfBirthYear;
    this.httpUtils.saveV2PersonalDetailsData(this.personalDetailsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.markFieldValueUnchanged(event, this.personalDetailsFormObject);
        if (this.shallExitApplication === this.constants.TRUE) {
          this.openInformationModal();
          return;
        }

        if (this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.applicationStage = this.constants.CONTACT_INFO_STAGE;
        this.applicationHighestStage = this.constants.CONTACT_INFO_STAGE;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openKinInformationForm(event: any): void {
    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }

    window.scrollTo(0, 0);
    this.loaderService.startLoader();
    this.contactInformationFormObject.applicantId = this.applicantId;
    this.contactInformationFormObject.contactState = this.stateOfOriginValue;
    this.setCurrentAsPermanentAddress(true);
    this.httpUtils.saveV2ContactInformationData(this.contactInformationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.contactInformationFormObject.contactDetailsId = serverResponse.contactDetailsId;
        this.markFieldValueUnchanged(event, this.contactInformationFormObject);
        if (this.shallExitApplication === this.constants.TRUE) {
          this.openInformationModal();
          return;
        }

        if (this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.applicationStage = this.constants.KIN_INFO_STAGE;
        this.applicationHighestStage = this.constants.KIN_INFO_STAGE;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openSupportingDocumentsForm(event: any): void {
    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }

    window.scrollTo(0, 0);
    this.loaderService.startLoader();
    this.kinInformationFormObject.applicantId = this.applicantId;
    this.httpUtils.saveV2KinInformationData(this.kinInformationFormObject).then(serverResponse => {
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.kinInformationFormObject.contactDetailsId = serverResponse.contactDetailsId;
        this.markFieldValueUnchanged(event, this.kinInformationFormObject);
        if (this.shallExitApplication === this.constants.TRUE) {
          this.loaderService.stopLoader();
          this.openInformationModal();
          return;
        }

        if (this.menuClickedStage > this.constants.ANY) {
          this.loaderService.stopLoader();
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.storeApplicableDocs().then(result => {
          if (result === true) {
            this.applicationStage = this.constants.DOCUMENTS_UPLOADED_STAGE;
            this.applicationHighestStage = this.constants.DOCUMENTS_UPLOADED_STAGE;
          }
        });
      } else {
        this.loaderService.stopLoader();
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private async storeApplicableDocs(): Promise<boolean> {
    let result: boolean = false;
    await this.httpUtils.fetchApplicableDocs(this.applicantId).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.applicableDocsList = serverResponse.applicableDocsList;
        result = true;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
    return result;
  }

  openPassportDelivryForm(event: any): void {
    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }

    window.scrollTo(0, 0);
    this.loaderService.startLoader();
    this.supportingDocumentsFormObject.applicantId = this.applicantId;
    this.supportingDocumentsFormObject.documents = this.fetchUploadedDocs();
    this.httpUtils.saveApplicableDocs(this.supportingDocumentsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        if (this.shallExitApplication === this.constants.TRUE) {
          this.openInformationModal();
          return;
        }

        if (this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.applicationStage = this.constants.PASSPORT_DELIVERY_STAGE;
        this.applicationHighestStage = this.constants.PASSPORT_DELIVERY_STAGE;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openApplicationPreview(event: any): void {
    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }

    window.scrollTo(0, 0);
    this.loaderService.startLoader();
    this.passportDeliveryFormObject.applicantId = this.applicantId;
    this.httpUtils.savePassportDeliveryDetails(this.passportDeliveryFormObject).then(serverResponse => {
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.loaderService.stopLoader();
        this.passportDeliveryFormObject.deliveryInfoId = serverResponse.deliveryInfoId;
        this.markFieldValueUnchanged(event, this.passportDeliveryFormObject);

        if (this.shallExitApplication === this.constants.TRUE) {
          this.openInformationModal();
          return;
        }

        this.fetchPassportFeeDetails();

        if (this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.gui.openApplicationViewV2(this.applicantId);
      } else if (serverResponse.responseCode === this.constants.MANDATORY_DOCUMENT_NOT_UPLOADED) {
        this.storeApplicableDocs();
        this.toast.showErrorToast(serverResponse.responseMsg);
      } else {
        this.loaderService.stopLoader();
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openApplicationPreviewforDocumentStage(event: any): void {
    if (event.target.name === undefined || event.target.name === this.constants.BLANK) {
      return;
    }

    window.scrollTo(0, 0);
    this.loaderService.startLoader();
    this.supportingDocumentsFormObject.applicantId = this.applicantId;
    this.supportingDocumentsFormObject.documents = this.fetchUploadedDocs();
    this.httpUtils.saveApplicableDocs(this.supportingDocumentsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.markFieldValueUnchanged(event, this.supportingDocumentsFormObject);
        if (this.shallExitApplication === this.constants.TRUE) {
          this.openInformationModal();
          return;
        }

        if (this.menuClickedStage > this.constants.ANY) {
          this.applicationStage = this.menuClickedStage;
          return;
        }

        this.gui.openApplicationViewV2(this.applicantId);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  checkAndOpenAfterDocumentUploadStage(event: any): void {
    if (this.enablePassportDeliveryStage === true) {
      this.openPassportDelivryForm(event);
    } else {
      this.openApplicationPreviewforDocumentStage(event);
    }
  }

  private fetchUploadedDocs(): any[] {
    const documents = [];
    for (let sectionObject of this.applicableDocsList) {
      for (let applicableDocObject of sectionObject.groupDocs) {
        documents.push({ applicantDocId: applicableDocObject.applicantDocId, docSubType: applicableDocObject.docSubType, base64Data: applicableDocObject.base64Data });
      }
    }
    return documents;
  }

  openImageUploadModal(formObject: {}, fileDataField: string, fileTypeField: string): void {
    const requestObject = {};
    requestObject['isFixedCropper'] = this.constants.TRUE;
    requestObject['allowFileTypeList'] = this.allowImagesList;
    requestObject['applicantId'] = this.applicantId;
    this.simpleModalService.addModal(ImageUploadModalComponent, { requestObject: requestObject }, { autoFocus: true }).subscribe((responseObject: any = {}) => {
      if (Object.keys(responseObject).length > 0) {
        this.toast.showSuccessToastOld('Picture Uploaded Successfully');
        formObject[fileDataField] = responseObject.fileBase64Data;
        formObject[fileTypeField] = responseObject.fileType;
        this.imageValidated = this.utils.isEmpty(responseObject.fileBase64Data) === false;
        if (this.applicationHighestStage === this.constants.DOCUMENTS_UPLOADED_STAGE) {
          this.storeApplicableDocs();
        }
      }
    });
  }

  openFileUploadModal(applicableDocObject: any = {}): void {
    this.simpleModalService.addModal(FileUploadModalComponent, { applicableDocObject }, { autoFocus: true }).subscribe((responseObject: any = {}) => {
      if (Object.keys(responseObject).length > 0 && this.utils.isEmpty(responseObject.base64Data) === false) {
        applicableDocObject.docSubType = responseObject.docSubType;
        applicableDocObject.docSubTypeLabel = responseObject.docSubTypeLabel;
        applicableDocObject.base64Data = responseObject.base64Data;
        applicableDocObject.isPDFDoc = responseObject.isPDFDoc;
        applicableDocObject.isDocUploaded = true;
      }
    });
  }

  openFilePreviewModal(applicableDocObject: any = {}): void {
    this.simpleModalService.addModal(FilePreviewModalComponent, { applicableDocObject }, { autoFocus: true, closeOnEscape: true }).subscribe();
  }

  deleteUploadedFile(applicableDocObject: any = {}): void {
    this.loaderService.startLoader();
    applicableDocObject.base64Data = this.constants.BLANK;
    applicableDocObject.isPDFDoc = false;
    applicableDocObject.isDocUploaded = false;
    this.loaderService.stopLoader();
  }

  private refrenceNumberInfoModal(): void {
    const message = `Please copy the <b>Reference number ${this.refrenceNumber}</b> for this application. This will be required for retrieval of your application and Appointment Booking.`;
    const checkBoxLabel = 'I confirm that I have copied my reference number.';
    this.simpleModalService.addModal(InformationModalComponent, { message: message, isShowCheckBox: true, checkBoxLabel: checkBoxLabel }, { autoFocus: true }).subscribe();
  }

  private setAppointmentAvailabilityURL(processingOfficeName: string): void {
    this.appointmentAvailabilityURL = environment.centreAvailabilityUrl + 'centers?center=' + processingOfficeName;
  }

  private markFieldValueUnchanged(event: any, formObject: {}) {
    event.target.name = this.constants.BLANK;
    formObject['hasFieldValueChanged'] = this.constants.FALSE;
  }

  checkPremiumCenter(event: any): void {
    const selectedOption = event.source.selected._element.nativeElement;
    const isPremium = Number(selectedOption.getAttribute('data'));
    if (isPremium === this.constants.TRUE) {
      this.openPremiumCenterInfoModal();
    } else {
      this.passportTypeFormObject.isPremium = this.constants.FALSE;
    }
  }

  private openPremiumCenterInfoModal() {
    const message = 'Please note this is a premium service and it will require additional fees. Click <b>OK</b> to continue.';
    const checkBoxLabel = 'I hereby confirm that I am opting for the premium service and agree to pay the associated charges.';
    this.simpleModalService.addModal(InformationModalComponent, { message: message, isShowCheckBox: true, checkBoxLabel: checkBoxLabel }, { autoFocus: true }).subscribe((isConfirmed: boolean) => {
      if (isConfirmed === true) {
        this.passportTypeFormObject.isPremium = this.constants.TRUE;
      }
    });
  }

  setAppointmentAvailabilityURLV2(event: any): void {
    const selectedOption = event.source.selected._element.nativeElement;
    const isPremium = Number(selectedOption.getAttribute('data'));

    let processingOfficeName = this.constants.BLANK;
    if (isPremium === this.constants.TRUE) {
      processingOfficeName = selectedOption.getAttribute('label');
    } else {
      processingOfficeName = event.source.triggerValue;
    }
    this.setAppointmentAvailabilityURL(processingOfficeName);
  }

}
