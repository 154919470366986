<app-header></app-header>

<div class="banner-img-name"></div>

<main class="body_section">
  <div class="main">
    <h3 class="w100 payment-heading main-heading">Make Payment</h3>
    <form action="" method="post" name="paymentForm" #paymentForm="ngForm"
      (ngSubmit)="paymentForm.form.valid && makePayment()" autocomplete="off" id="msform1"
      style="width: 100%; float: left">
      <input type="hidden" name="applicationId" value="{{ applicationPaymentDetailsObject.applicationId }}" />
      <input type="hidden" name="currencyCode" value="{{ applicationPaymentDetailsObject.currencyCode }}" />
      <input type="hidden" name="totalAmount" value="{{ applicationPaymentDetailsObject.totalFee }}" />
      <input type="hidden" name="transactionFee" value="{{ applicationPaymentDetailsObject.transactionFee }}" />
      <input type="hidden" name="serviceCharge" value="{{ applicationPaymentDetailsObject.serviceCharge }}" />
      <input type="hidden" name="paymentMode" value="{{ paymentFormObject.paymentMethod }}" />
      <input type="hidden" name="paymentType" value="{{ constants.APPLICATION_PAYMENT }}" />
      <input type="hidden" name="passportType" value="{{ applicationPaymentDetailsObject.passportTypeKey }}" />
      <input type="hidden" name="authToken" value="" />

      <div class="pay-info">
        <p>
          Application Number
          <span style="color: green">{{
            applicationPaymentDetailsObject.applicationId
            }}</span>
        </p>
        <p>
          {{ referenceLabel }}
          <span style="color: green">{{
            applicationPaymentDetailsObject.referenceNumber
            }}</span>
        </p>
      </div>

      <div class="box_row">
        <div class="grid-two">
          <div class="passport_fees">
            <h4>Fee Detail</h4>
            <table>
              <tr>
                <td>Applicant Name</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.fullName }}</b>
                </td>
              </tr>

              <tr>
                <td>Passport Type</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.passportType }}</b>
                </td>
              </tr>

              <tr *ngIf="applicationPaymentDetailsObject.codFee > 0">
                <td>COD Fee</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    applicationPaymentDetailsObject.codFee
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>

              <tr>
                <td>Application Fee</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    applicationPaymentDetailsObject.applicationFee
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>

              <tr *ngIf="
                  applicationPaymentDetailsObject.addressVerificationFee > 0
                ">
                <td>Address Verification Fee</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    applicationPaymentDetailsObject.addressVerificationFee
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>

              <tr *ngIf="
                  applicationPaymentDetailsObject.addressVerificationServiceCharge >
                  0
                ">
                <td>Address Verification Service Charge</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    applicationPaymentDetailsObject.addressVerificationServiceCharge
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>

              <tr *ngIf="applicationPaymentDetailsObject.deliveryFee > 0">
                <td>Passport Delivery Fee</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    applicationPaymentDetailsObject.deliveryFee
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>

              <div class="d-none">
                <tr *ngIf="
                    applicationPaymentDetailsObject.deliveryServiceCharge > 0
                  ">
                  <td>Passport Delivery Service Charge</td>
                  <td style="text-align: right">
                    <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                      {{
                      applicationPaymentDetailsObject.deliveryServiceCharge
                      | number : "1.2" : "en-US"
                      }}</b>
                  </td>
                </tr>
              </div>

              <tr>
                <!-- <td>Processing Fee ({{utils.isEmpty(paymentFormObject.paymentMethod) === true ? 'Select gateway & mode' : applicationPaymentDetailsObject.paymentMethodLabel}})</td> -->
                <td>Processing Fee</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    applicationPaymentDetailsObject.transactionFee
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>

              <tr *ngIf="applicationPaymentDetailsObject.serviceCharge > 0">
                <td>Service Charge</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    applicationPaymentDetailsObject.serviceCharge
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>

              <tr class="feeTotal">
                <td>Total Fee</td>
                <td style="text-align: right">
                  <b>{{ applicationPaymentDetailsObject.currencySymbol }}
                    {{
                    (applicationPaymentDetailsObject.deliveryServiceCharge
                    ? (applicationPaymentDetailsObject.totalFeeIncludeServiceFee -
                    applicationPaymentDetailsObject.deliveryServiceCharge)
                    : applicationPaymentDetailsObject.totalFeeIncludeServiceFee)
                    | number : "1.2" : "en-US"
                    }}</b>
                </td>
              </tr>
            </table>

            <!-- <p class="gateway-fee-note"><b>Note:</b> There might be additional Payment Gateway fees.</p> -->
          </div>

          <div style="padding: 10px 20px 5px 0">
            <h4 class="payment-h4">Choose Payment Method</h4>

            <div class="faq">
              <ng-container *ngFor="let gatewayObject of gatewayDetailsList">
                <input type="checkbox" class="gateway" [id]="gatewayObject.key" checked="checked" disabled="disabled" />

                <label [for]="gatewayObject.key" class="faq-l payment-method-item">
                  <div class="payment-left">
                    <p class="faq-heading">{{ gatewayObject.label }}</p>
                    <p class="faq-text">
                      <ng-container *ngFor="let gatewayItemObject of gatewayObject.items">
                        <span class="faq-text-list">
                          <input type="radio" name="paymentMethod" [(ngModel)]="paymentFormObject.paymentMethod"
                            #paymentMethod="ngModel" [value]="gatewayItemObject.paymentMethod" (ngModelChange)="
                              setTransactionFee();
                              setIsSubmitForm(gatewayItemObject.isSubmitReq)
                            " required="required" [id]="gatewayItemObject.paymentMethod" />
                          <label [for]="gatewayItemObject.paymentMethod">{{
                            gatewayItemObject.paymentMethodLabel
                            }}</label>
                        </span>
                        <span *ngIf="
                            gatewayItemObject.lastItem === true &&
                            paymentForm.submitted &&
                            paymentMethod.invalid
                          " class="alert alert-danger">
                          <span *ngIf="paymentMethod.errors.required">
                            Payment Method is required!
                          </span>
                        </span>
                      </ng-container>
                    </p>
                  </div>

                  <div class="faq-arrow">
                    <img [src]="gatewayObject.imageName" [ngStyle]="{
                        width:
                          gatewayObject.key === constants.PAYFIXY
                            ? '110px'
                            : '85px'
                      }" />
                  </div>
                  <!-- <div class='faq-arrow'>
                                        <img [src]="gatewayObject.imageName">
                                    </div> -->
                </label>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="imp_info mt10 w100">
        <div class="element">
          <h4>Important Instructions</h4>
          <ol style="padding-left: 15px">
            <li>
              This application will expire on
              {{ applicationPaymentDetailsObject.applicationExpiryDate }}.
              Please make payment before this date.
            </li>
            <li>Select gateway & mode of payment.</li>
            <li>
              The processing fee depends on the gateway & mode of payment.
            </li>
            <li>
              If you are selecting cash @ bank as payment mode, please print
              acknowledgement slip. Take note of the expiry date on the
              acknowledgement slip.
            </li>
            <li>
              Multiple acknowledgement slips can be generated until the
              application expires.
            </li>
          </ol>
        </div>
      </div>

      <div style="text-align: center; margin-bottom: 40px">
        <input type="button" (click)="backToApplicationView()" value="Cancel"
          class="previous action-button-previous back_btn" />
        <input type="submit" value="Pay {{ applicationPaymentDetailsObject.currencySymbol }} {{
           ( applicationPaymentDetailsObject.deliveryServiceCharge ? (applicationPaymentDetailsObject.totalFeeIncludeServiceFee - applicationPaymentDetailsObject.deliveryServiceCharge) : applicationPaymentDetailsObject.totalFeeIncludeServiceFee)
              | number : '1.2' : 'en-US'
          }}" [disabled]="
            isPayButtonDisabled === true ||
            applicationPaymentDetailsObject.totalFeeIncludeServiceFee === 0
          " class="next action-button custom_btn" />
      </div>
    </form>
  </div>
</main>

<app-footer></app-footer>