import { Injectable } from '@angular/core';

@Injectable({ 
    providedIn: 'root'
})
export class UserType {

    // DIPLOMAT_1 = 'foreign_mission';

    DIPLOMAT_1 = 'diplomat_1';

}
