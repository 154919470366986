<div id="notfound">
    <div class="notfound">
        <div>
            <div class="notfound-404">
                <h1>!</h1>
            </div>
            <h2>Something went wrong</h2>
        </div>
        <p>The page you are looking for might have been removed or had its name changed or is temporarily unavailable.</p>
        <a routerLink="">Back to homepage</a>
    </div>
</div>