import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({ 
    providedIn: 'root'
})
export class LoaderService {

    constructor(private ngxUILoaderService: NgxUiLoaderService){
    }

    startLoader(){
        this.ngxUILoaderService.startLoader("master");
    }

    stopLoader(){
        this.ngxUILoaderService.stopLoader('master');
    }

}