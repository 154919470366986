import { Injectable } from '@angular/core';
import { Tags } from './tags';
import { Utils } from './utils';

@Injectable({ 
    providedIn: 'root'
})
export class LocalStorage {
    private storage: Storage;

    constructor(private tags: Tags, private utils: Utils, ){
        this.storage = window.localStorage;
    }

    setStorageKey(keyName: string, keyValue: string){
        this.storage.setItem(keyName, keyValue);
    }

    getStorageKey(keyName: string){
        return this.storage.getItem(keyName) != null ? this.storage.getItem(keyName) : "";
    }

    removeStorageKey(keyName: string){
        this.storage.removeItem(keyName);
    }

    removeAllStorageData(){
        this.storage.clear();
    }

    getApplicationToken(): string {
        return this.getStorageKey(this.tags.APPLICATION_TOKEN);
    }

    getLoggedUserId() {
        const loggedUserId = this.getStorageKey(this.tags.LOGGED_USER_ID);
        return this.utils.decryptString(loggedUserId);
    }

    getLoggedUserFullName() {
        const loggedUserFullName = this.getStorageKey(this.tags.LOGGED_USER_NAME);
        return this.utils.decryptString(loggedUserFullName);
    }

}
