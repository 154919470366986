import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from './constants';
import { Utils } from './utils';
import { Route } from './route';

@Injectable({
    providedIn: 'root'
})
export class GUI {

    constructor(private ngZone: NgZone, private router: Router, private constants: Constants, private utils: Utils, private route: Route) {

    }

    openHomePage() {
        this.ngZone.run(() => this.router.navigate([this.route.HOME]));
    }

    openContactlessPage() {
        this.ngZone.run(() => this.router.navigate([this.route.CONTACTLESS]));
    }


    openApplicationAddEdit(applicantId: string, applicationStage: number) {
        applicationStage = applicationStage < this.constants.KIN_INFO_STAGE ? ++applicationStage : applicationStage;
        this.ngZone.run(() => this.router.navigate([this.route.APPLICATION_ADD_EDIT, this.utils.encryptString(applicantId), this.utils.encryptString(applicationStage.toString())]));
    }

    openApplicationView(applicantId: string, applicationStage: number) {
        if (applicationStage < this.constants.CONTACT_INFO_STAGE) {
            this.openApplicationAddEdit(applicantId, applicationStage);
            return;
        }

        this.ngZone.run(() => this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(applicantId)]));
    }

    openApplicationSearch(searchType: string) {
        this.ngZone.run(() => this.router.navigate([this.route.APPLICATION_SEARCH, this.utils.encryptString(searchType)]));
    }

    openApplicationGuarantor() {
        this.ngZone.run(() => this.router.navigate([this.route.GUARANTOR]));
    }

    openCODApprovedPage() {
        this.ngZone.run(() => this.router.navigate([this.route.COD_APPROVE]));
    }

    openCODView(passcode: string) {
        this.ngZone.run(() => this.router.navigate([this.route.COD_REQUEST_VIEW, this.utils.encryptString(passcode)]));
    }

    openPaymentPage(encryptedApplicantId: string, paymentType: string) {
        this.ngZone.run(() => this.router.navigate([this.route.APPLICATION_PAYMENT, encryptedApplicantId, this.utils.encryptString(paymentType)]));
    }

    openPaymentSuccessPage(serverEncryptedTxnRef: string) {
        this.ngZone.run(() => this.router.navigate([this.route.PAYMENT_SUCCESS, serverEncryptedTxnRef]));
    }

    openPaymentFailurePage(serverEncryptedTxnRef: string, serverEncryptedReferenceNumber: string, serverEncryptedApplicationId: string) {
        this.ngZone.run(() => this.router.navigate([this.route.PAYMENT_FAILURE, serverEncryptedTxnRef, serverEncryptedReferenceNumber, serverEncryptedApplicationId]));
    }

    openPaymentReceipt(txnRefNumber: string) {
        this.ngZone.run(() => this.router.navigate([this.route.PAYMENT_RECEIPT, this.utils.encryptString(txnRefNumber)]));
    }

    openEmailVerificationPage(): void {
        this.ngZone.run(() => this.router.navigate([this.route.EMAIL_VERIFICATION]));
    }

    // openNinVerificationPage(emailId: string): void{
    //     this.ngZone.run(()=>this.router.navigate([this.route.NIN_VERIFICATION, this.utils.encryptString(emailId)]));
    // }

    openNINVerificationPage(applyingFor: string): void {
        this.ngZone.run(() => this.router.navigate([this.route.NIN_VERIFICATION, this.utils.encryptString(applyingFor)]));
    }

    openNINVerificationPageForCOD(applicationType: string, dataCorrectionId: string): void {
        this.ngZone.run(() => this.router.navigate([this.route.NIN_VERIFICATION, this.utils.encryptString(applicationType), this.utils.encryptString(dataCorrectionId)]));
    }

    openApplicationAddEditPage(applicantId: string): void {
        this.ngZone.run(() => this.router.navigate([this.route.APPLICATION_ADD_EDIT, this.utils.encryptString(applicantId)]));
    }

    openApplicationViewV2(applicantId: string): void {
        this.ngZone.run(() => this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(applicantId)]));
    }

    openCODDataComparisonPage(dataCorrectionId: string): void {
        this.ngZone.run(() => this.router.navigate([this.route.COD_DATA_COMPARISON, this.utils.encryptString(dataCorrectionId)]));
    }

    openPassportVerificationPage(dataCorrectionId: string, applyingFor: string) {
        this.ngZone.run(() => this.router.navigate([this.route.COD_REQUEST, this.utils.encryptString(dataCorrectionId), this.utils.encryptString(applyingFor)]));
    }

}
