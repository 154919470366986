import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface InformationModalInterface {
  message: string;
  isShowCheckBox: boolean;
  checkBoxLabel: string;
}
@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.css']
})
export class InformationModalComponent extends SimpleModalComponent<InformationModalInterface, boolean> implements InformationModalInterface {

  message: string;
  
  isShowCheckBox: boolean;
  
  checkBoxLabel: string;
  
  isTCSelected: boolean = false;

  constructor() { 
    super();
  }

  confirm(): void {
    this.result = true;
    this.close();
  }

}
