import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Route {

    HOME = '';

    EMAIL_VERIFICATION = 'email';

    NIN_VERIFICATION = 'nin';

    APPLICATION_ADD_EDIT = 'application';

    APPLICATION_VIEW = 'view';

    COD_DATA_COMPARISON = 'comparison';

    LOGOUT = 'logout';

    PAGE_NOT_FOUND = '**';

    IMAGE_TEST = 'image-compliance'

    APPLICATION_REUPLOAD = 'reupload'

    APPLICATION_SEARCH = 'search'

    APPLICATION_PAYMENT = 'payment'

    PAYMENT_RECEIPT = 'receipt'

    PAYMENT_SUCCESS = 'success'

    PAYMENT_FAILURE = 'failure'

    COD_REQUEST = 'request'

    COD_APPROVE = 'approve'

    COD_REQUEST_VIEW = 'perspective'

    GUARANTOR = 'guarantor'

    CONTACTLESS = 'contactless-response'

    CONTACTLESS_PAYMENT = 'contactless-payment'

}
