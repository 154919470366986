import { Injectable } from '@angular/core';
import { Ngxalert } from 'ngx-dialogs';
import { ToastrService } from 'ngx-toastr';
import { Constants } from './constants';

@Injectable({ 
    providedIn: 'root'
})
export class Toast {

    private ngxalert = new Ngxalert();

    private toasterOptions : any = {};

    constructor(private toaster: ToastrService, private constants: Constants){
        this.toasterOptions = {
            progressBar: true,
            timeOut: this.constants.TOASTER_DELAY,
            closeButton: true
        }
    }

    showSuccessToastOld(message: string){
        this.toaster.success(message, this.constants.BLANK, this.toasterOptions);
    }

    showSuccessToast(message: string){
        this.showDialog("Confirmation", message, 'dialog-success', null);
    }

    showErrorToast(message: string){
        this.showDialog("Error", message, 'dialog-error', null);
    }

    showErrorToastWithCallbackFunction(message: string, callbackFunction: Function){
        this.showDialog("Error", message, 'dialog-error-no-close', callbackFunction);
    }

    showWarningToast(message: string){
        this.showDialog("Warning", message, 'dialog-error', null);
    }

    private showDialog(title: string, message: string, styleClass: string, callbackFunction: Function){
        this.ngxalert.create({ 
	    	title: title,
	      	message:  message,
	      	strict:  true,
            customCssClass: styleClass,
	      	buttons: [
	        	{
	          		title :  'OK',
	          		class:  'ok-btn',
	          		event : () => {
                        if(callbackFunction !== null){
                            callbackFunction();
                        }
	            		const closeSpan = <HTMLElement>document.querySelectorAll('.close-dialog')[0];
	            		closeSpan.click();
	          		}
	        	}
	      	]
	    });
    }

}
