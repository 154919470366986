<app-header></app-header>
<div class="banner-img-name"></div>

<div id="" class="center_body">
    <div class="card p10">


        <h2>Pending ...</h2>
        <p>
            Contactless passport application
        </p>

        <div class="mt30">
            <a routerLink="" class="custom_btn">Retry</a>
        </div>

    </div>
</div>
<app-footer></app-footer>