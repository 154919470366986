<app-header></app-header>

<div class="banner-img-name mb10"></div>

<main class="body_section">

    <div class="main">
        
        <h3 class="main-heading" style="padding-bottom: 0;">{{pageTitle}}</h3>

        <fieldset class="mb30">

            <div class="step_info">
                <span>National Identification Number (NIN)</span>
            </div>

            <div class="form-card" style="padding: 0;">
            
                <form #ninVerificationForm="ngForm" (ngSubmit)="ninVerificationForm.form.valid && captchaComponent.verifyCaptcha()" [validateDateOfBirth]="['dateOfBirthDay', 'dateOfBirthMonth', 'dateOfBirthYear', '',constants.FALSE]" autocomplete="off" name="ninVerificationForm" appFocus> 
                    
                    <div class="modal-body">

                        <div class="centered">

                            <details>
                                <summary>Important Notice</summary>
                                <div class="content">
                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                </div>
                            </details>

                            <div class="search_grid">
                                <div class="grid-boxs">
                                    <div class="form_box" >
                                        <label class="fieldlabels">NIN <span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <input type="text" name="nin" hint numberOnly [(ngModel)]="ninVerificationFormObject.nin" #nin="ngModel" class="effect" required="required" minlength="11" maxlength="11" firstFocus>
                                            <span class="tooltip"><span class='tooltipArrow'></span>Your National Identification Number (NIN) is a unique identifier issued by the government.</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span *ngIf="ninVerificationForm.submitted && nin.invalid" class="alert alert-danger" role="alert">
                                            <span *ngIf="nin.errors.required" >
                                                NIN is required!
                                            </span>
                                            <span *ngIf="nin.errors.minlength" >
                                                NIN should have 11 digits!
                                            </span>
                                        </span>
                                    </div>
        
                                    <div class="form_box">
                                        <label class="fieldlabels">Date of Birth (dd/mm/yyyy) <span class="medatory">*</span></label>
                                        <div class="grid-date">
                                            <div class="input_box">
                                                <input type="text" name="dateOfBirthDay" hint numberOnly [(ngModel)]="ninVerificationFormObject.dateOfBirthDay" #dateOfBirthDay="ngModel" class="effect" required="required" maxlength="2" placeholder="DD">
                                                <span class="tooltip"><span class='tooltipArrow'></span>Please enter the date on which you were born. This should be matched with your NIN document.</span>
                                                <span class="focus-border "><i></i></span>
                                            </div>
        
                                            <div class="input_box">
                                                <select name="dateOfBirthMonth" hint [(ngModel)]="ninVerificationFormObject.dateOfBirthMonth" #dateOfBirthMonth="ngModel" class="effect" required="required">
                                                    <option *ngFor="let object of monthsList;" [value]="object.value">{{object.text}}</option>
                                                </select>
                                                <span class="tooltip"><span class='tooltipArrow'></span>Choose the month in which you were born. This should be matched with your NIN document.</span>
                                                <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                                <span class="focus-border "><i></i></span>
                                            </div>
        
                                            <div class="input_box">
                                                <input type="text" name="dateOfBirthYear" hint numberOnly [(ngModel)]="ninVerificationFormObject.dateOfBirthYear" #dateOfBirthYear="ngModel" class="effect" required="required" minlength="4" maxlength="4" placeholder="YYYY">
                                                <span class="tooltip"><span class='tooltipArrow'></span>Please enter the year in which you were born. This should be matched with your NIN document.</span>
                                                <span class="focus-border "><i></i></span>
                                            </div>
                                        </div>
                                        <span class="alert alert-danger" role="alert" *ngIf="ninVerificationForm.submitted && (dateOfBirthDay.invalid || dateOfBirthMonth.invalid || dateOfBirthYear.invalid)">
                                            <span *ngIf="(dateOfBirthDay.errors?.required || dateOfBirthMonth.errors?.required || dateOfBirthYear.errors?.required)">
                                                Date of Birth is required!
                                            </span>
                                            <span *ngIf="dateOfBirthYear.errors?.minlength">
                                                Date of Birth's year should be 4 digits!
                                            </span>
                                            <span *ngIf="!dateOfBirthYear.errors?.minlength && dateOfBirthDay.errors?.hasError">
                                                {{dateOfBirthDay.errors.errorMessage}}
                                            </span>
                                        </span>
                                    </div>
                                    
                                    <div class="captcha captchaForm relative">
                                        <app-captcha [currentForm]="ninVerificationForm" (callbackFunction)="validateNIN()" ></app-captcha>
                                    </div>

                                    <div style="float: left;">
                                        <input type="button" value="Reset" (click)="reset()" class="save_btn">
                                        <input type="submit" class="custom_btn mleft0" value="Verify" [disabled]="showNINDetailsSection === true "/>
                                    </div>
        
                                </div>  
                                
                                <div class="grid-boxs upload_inst">
                                    <div  class="imp_info">
                                        <div  class="element none_dis">
                                            <h4>Important Notice</h4>
                                            <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                        </div>
                                    </div>
                                </div> 
        
                            </div>
                        </div>

                        <ng-container *ngIf="showNINDetailsSection === true">

                            <div class="mt15">
    
                                <div class="w100 personal_details_section mt10">
                                    
                                    <h3 class="main-heading">Your Personal Details</h3>
                                    
                                    <p class="pb10">This information has been retrieved from your NIN record and cannot be edited. Your passport will be printed with this information. If all the information is correct, then click on the <b>Confirm & Continue</b> button 
                                        <br>OR if you want to change any information then you will need to get it changed from your NIN record.
                                    </p>
                                    
                                    <table>
                                        <tr>
                                            <td><b>First Name</b></td>
                                            <td><span>{{ninDetailsObject.firstName}}</span></td>
                                        </tr> 
    
                                        <tr>
                                            <td><b>Middle Name</b></td>
                                            <td><span>{{ninDetailsObject.middleName}}</span></td>
                                        </tr> 
    
                                        <tr>
                                            <td><b>Last Name</b></td>
                                            <td><span>{{ninDetailsObject.lastName}}</span></td>
                                        </tr> 
    
                                        <tr>
                                            <td><b>Date of Birth</b></td>
                                            <td><span>{{ninDetailsObject.dateOfBirth}}</span></td>
                                        </tr>
                                        
                                        <tr>
                                            <td><b>Gender</b></td>
                                            <td><span>{{ninDetailsObject.gender}}</span></td>
                                        </tr>

                                        <tr>
                                            <td><b>Marital Status</b></td>
                                            <td><span>{{ninDetailsObject.maritalStatus}}</span></td>
                                        </tr>
    
                                        <tr>
                                            <td><b>Place of Birth</b></td>
                                            <td><span>{{ninDetailsObject.placeOfBirth}}</span></td>
                                        </tr>

                                        <tr>
                                            <td><b>Maiden Name</b></td>
                                            <td><span>{{ninDetailsObject.motherMaidenName}}</span></td>
                                        </tr>
                                    </table>    
    
                                </div>
    
                                <div class="w100 mb15">
                                    <input type="button" (click)="saveNINDetails()" value="Confirm & Continue" class="custom_btn mleft0"/>
                                </div>
    
                            </div>
                        
                        </ng-container>

                    </div>

                </form>

            </div>

        </fieldset>

    </div>
    
</main>

<app-footer></app-footer>
 
<ng-template #instructions>
    <ul>
        <li>You are guaranteed the privacy of your data</li>
        <li>All fields that contain the Asterisk (*) sign are mandatory and must be completed.</li>
    </ul>
</ng-template>
