import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { LoaderService } from '../common/loader-service';
import { HttpUtils } from '../common/http-utils';

export interface documentsHistoryInterface {
  requestObject: {};
}

@Component({
  selector: 'app-documents-upload-history-modal',
  templateUrl: './documents-upload-history-modal.component.html',
  styleUrls: ['./documents-upload-history-modal.component.css']
})
export class DocumentsUploadHistoryModalComponent extends SimpleModalComponent<documentsHistoryInterface, {}> implements documentsHistoryInterface, OnInit {

  requestObject: any = {};

  documentHistoryList: any [] = []; 

  isPDFDoc: boolean = false;

  base64Data: string = this.constants.BLANK;

  clickedRowNumber: number = this.constants.ANY;

  constructor(public constants: Constants, private toast: Toast, public utils: Utils, private loaderService: LoaderService, private httpUtils: HttpUtils) {
    super();
  }

  ngOnInit(): void {
    this.fetchDocHistory();
  }

  private fetchDocHistory(): void {
    setTimeout(() => {this.loaderService.startLoader();}, 0);
    this.httpUtils.fetchDocHistory(this.requestObject).then(serverResponse => {
      setTimeout(() => {this.loaderService.stopLoader();}, 0);
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.documentHistoryList = serverResponse.docHistory;
      } else {  
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private storeDocBase64Data(applicantDocId: string): void {
    setTimeout(() => {this.loaderService.startLoader();}, 0);

    this.httpUtils.fetchDocBase64Data(applicantDocId).then(serverResponse => {
      setTimeout(() => {this.loaderService.stopLoader(); }, 0);
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.isPDFDoc = serverResponse.details.isPDFDoc;
        this.base64Data = serverResponse.details.base64Data;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  viewDocument(docObject: any={}, rowNumber: number): void {
    this.clickedRowNumber = rowNumber;
    this.storeDocBase64Data(docObject.applicantDocId);
  }

  cancel(): void {
    this.result = {};
    this.close();
  }

}
