import { Constants } from "./constants";
import { Injectable } from '@angular/core';
import { SelectService } from "./select-service";
import * as CryptoJS from 'crypto-js';
import { CroppieOptions, ResultOptions } from "croppie";
import { UserType } from "./user-type";
import { environment } from "src/environments/environment";

declare function showJSToolTip(tagNameAttr: string): any;
declare function hideJSToolTip(tagNameAttr: string): any;

@Injectable({
    providedIn: 'root'
})
export class Utils {

    resultOptions: ResultOptions = { type: 'base64', size: 'original', format: 'jpeg', quality: 1, circle: false };

    croppieOptions: CroppieOptions = { boundary: { width: 375, height: 375 }, viewport: { width: 264, height: 340, type: 'square' }, enableExif: true, enableOrientation: true, enableZoom: true, enableResize: false, maxZoom: 0, minZoom: 0, mouseWheelZoom: true, showZoomer: true };

    orientation: number = 1;

    zoom: number = 0;

    constructor(private constants: Constants, private userType: UserType) {

    }

    getYesNoLabel(status: number) {
        if (status === this.constants.TRUE) {
            return "Yes";
        }
        return "No";
    }

    isEmpty(value: string) {
        return (value === undefined || value === null || value === this.constants.BLANK);
    }

    showToolTip(tagNameAttr: string) {
        showJSToolTip(tagNameAttr);
    }

    hideToolTip(tagNameAttr: string) {
        hideJSToolTip(tagNameAttr);
    }

    private getPassportTypeLabel() {
        const passportTypeLabelObject: any = {};
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT] = "Fresh Passport";
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT_RENEWAL_REISSUE] = "Renewal/Re-issue of Passport";
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT_COD] = "Re-issue of Passport";
        passportTypeLabelObject[this.constants.OFFICIAL_PASSPORT] = "Official Passport";
        return passportTypeLabelObject;
    }

    fetchPassportTypeLabel(passportTypeKey: string) {
        if (this.isEmpty(passportTypeKey) === true) {
            return "Passport";
        }
        const passportTypeLabelObject = this.getPassportTypeLabel();
        return passportTypeLabelObject[passportTypeKey];
    }

    storeChangeTypes(changeTypeSelectService: SelectService, applyingFor: string) {
        const changeTypeList = [{ value: this.constants.BLANK, text: "Choose Option" }];
        if (applyingFor === this.constants.REISSUE) {
            changeTypeList.push({ value: this.constants.UNEXPIRED, text: "Active" });
        }
        changeTypeList.push({ value: this.constants.EXPIRED, text: "Expired" });
        changeTypeList.push({ value: this.constants.PAGE_EXHAUSTED, text: "Page Exhausted" });
        changeTypeList.push({ value: this.constants.LOST_REPLACEMENT_REQUEST, text: "Lost/Replacement Request" });
        changeTypeList.push({ value: this.constants.DAMAGED_PASSPORT, text: "Damaged Passport" });
        changeTypeList.push({ value: this.constants.CONVENTIONAL_PASSPORT, text: "Conventional Passport" });
        changeTypeSelectService.setSelectOptions(changeTypeList);
    }

    getNatureOfCitizenshipList(): {}[] {
        const natureOfCitizenshipOptions = [{ value: this.constants.BLANK, text: "Choose Option" }];
        natureOfCitizenshipOptions.push({ value: this.constants.BIRTH, text: "Birth" });
        natureOfCitizenshipOptions.push({ value: this.constants.NATURALIZATION, text: "Naturalization" });
        natureOfCitizenshipOptions.push({ value: this.constants.ADOPTION, text: "Adoption" });
        natureOfCitizenshipOptions.push({ value: this.constants.SURROGACY, text: "Surrogacy" });
        return natureOfCitizenshipOptions;
    }

    getSearchByParamListObject(encryptedMasterSearchType: string) {
        const decryptedMasterSearchType = this.decryptString(encryptedMasterSearchType);
        let searchByParamListObject: any = {};
        searchByParamListObject[this.constants.APPLICATION_ID] = "Application Number";
        searchByParamListObject[this.constants.DATE_OF_BIRTH] = "Date Of Birth";
        if (decryptedMasterSearchType !== this.constants.GUARANTOR_FORM) {
            searchByParamListObject[this.constants.EMAIL_ID] = "Email Id";
        }
        return searchByParamListObject;
    }

    encryptString(plainString: string) {
        if (this.isEmpty(plainString) === true) {
            return this.constants.BLANK;
        }

        const encryptedString = CryptoJS.AES.encrypt(plainString.trim(), this.constants.ENCRYPT_DECRYPT_KEY.trim()).toString();
        const encryptedBase64String = btoa(encryptedString);
        return encryptedBase64String;
    }

    decryptString(encryptedBase64String: string) {
        if (this.isEmpty(encryptedBase64String) === true) {
            return this.constants.BLANK;
        }

        const encryptedString = atob(encryptedBase64String);
        const plainString = CryptoJS.AES.decrypt(encryptedString.trim(), this.constants.ENCRYPT_DECRYPT_KEY.trim()).toString(CryptoJS.enc.Utf8);
        return plainString;
    }

    decryptServerEncryptedString(encryptedString: string) {
        if (this.isEmpty(encryptedString) === true) {
            return this.constants.BLANK;
        }
        const key = CryptoJS.PBKDF2(this.constants.ENCRYPT_DECRYPT_MASTER_PASSWORD, this.constants.ENCRYPT_DECRYPT_MASTER_SALT, { keySize: 4, iterations: 4 });
        const decryptedString = CryptoJS.AES.decrypt(encryptedString, key, { iv: CryptoJS.enc.Utf8.parse(this.constants.ENCRYPT_DECRYPT_MASTER_KEY), padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
        const plainString = decryptedString.toString(CryptoJS.enc.Utf8);
        return plainString;
    }

    removeSpace(inputString: string) {
        return inputString.replace(/\s/g, "");
    }

    getStatusClass(status: number) {
        if (status === this.constants.STATUS_INITIATE) {
            return "warning";
        }

        if (status === this.constants.STATUS_REJECTED) {
            return "danger";
        }

        return "success";
    }

    trimValue(formObject: {}, fieldName: string) {
        const fieldValue = formObject[fieldName];
        if (fieldValue === undefined || fieldValue === this.constants.BLANK) {
            return;
        }
        formObject[fieldName] = fieldValue.trim();
    }

    getMonthsArray() {
        const monthsList = [{ value: "", text: "Select Month" }];
        monthsList.push({ value: "01", text: "Jan" });
        monthsList.push({ value: "02", text: "Feb" });
        monthsList.push({ value: "03", text: "Mar" });
        monthsList.push({ value: "04", text: "Apr" });
        monthsList.push({ value: "05", text: "May" });
        monthsList.push({ value: "06", text: "Jun" });
        monthsList.push({ value: "07", text: "Jul" });
        monthsList.push({ value: "08", text: "Aug" });
        monthsList.push({ value: "09", text: "Sep" });
        monthsList.push({ value: "10", text: "Oct" });
        monthsList.push({ value: "11", text: "Nov" });
        monthsList.push({ value: "12", text: "Dec" });
        return monthsList;
    }

    setMobileNumberFields(countryInfoObject: {}, formObject: {}, fieldsArray: any[]) {
        formObject[fieldsArray[0]] = countryInfoObject['iso2'];
        formObject[fieldsArray[1]] = "+" + countryInfoObject['dialCode'];
    }

    storeApplicationSteps(applicationStepsList: any[]): void {
        applicationStepsList.push({ count: this.constants.PROCESSING_INFO_STAGE, id: 'passport-type', label: 'Passport Type' });
        applicationStepsList.push({ count: this.constants.UPLOAD_PHOTO_STAGE, id: 'image-upload', label: 'Passport Photograph' });
        applicationStepsList.push({ count: this.constants.APPLICANT_INFO_STAGE, id: 'personal', label: 'Personal Details' });
        applicationStepsList.push({ count: this.constants.CONTACT_INFO_STAGE, id: 'contact', label: 'Contact Information' });
        applicationStepsList.push({ count: this.constants.KIN_INFO_STAGE, id: 'kin', label: 'Next of Kin Information' });
        applicationStepsList.push({ count: this.constants.DOCUMENTS_UPLOADED_STAGE, id: 'documents-upload', label: 'Upload Supporting Documents' });

        if (environment.enablePassportDeliveryStage === true) {
            applicationStepsList.push({ count: this.constants.PASSPORT_DELIVERY_STAGE, id: 'passport-delivery', label: 'Passport Delivery Options' });
        }
    }

    getApplicationType(applyingFor: string): string {
        if (applyingFor === this.constants.FRESH || applyingFor === this.constants.RENEW) {
            return this.constants.PASSPORT_APPLICATION;
        } else {
            return this.constants.COD_APPLICATION;
        }
    }

    isValidUser(userTypeArray: any = []) {
        const validUserTypesArray = [this.userType.DIPLOMAT_1];
        for (let requestUserType of userTypeArray) {
            if (validUserTypesArray.includes(requestUserType) === true) {
                return true;
            }
        }
        return false;
    }

}