import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule, PB_DIRECTION, SPINNER } from "ngx-ui-loader";
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FlatpickrModule } from 'angularx-flatpickr';
import { HttpClientModule } from '@angular/common/http';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgxBarcodeModule } from 'ngx-barcode';
import { LoaderService } from './common/loader-service';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ApplicationAddEditComponent } from './application-add-edit/application-add-edit.component';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { NumberOnlyDirective } from './validators/number-only-directive';
import { SearchApplicationComponent } from './search-application/search-application.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { FocusDirective } from './validators/focus-directive';
import { DateOfBirthDirective } from './validators/date-of-birth.directive';
import { FirstFocusDirective } from './validators/first-focus-directive';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CODRequestComponent } from './codrequest/codrequest.component';
import { AlphaNumericOnlyDirective } from './validators/alpha-numeric-only-directive';
import { DateOfBirthInputDirective } from './validators/date-of-birth-input-directive';
import { HintDirective } from './validators/hint-directive';
import { NameDirective } from './validators/name-directive';
import { AddressDirective } from './validators/address-directive';
import { NameWithNumberDirective } from './validators/name-with-number-directive';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NoticeModalComponent } from './notice-modal/notice-modal.component';
import { NgxDialogsModule } from 'ngx-dialogs';
import { ValidatePhoneDirective } from './validators/validate-phone-directive';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { NinVerificationComponent } from './nin-verification/nin-verification.component';
import { NgxSuperCroppieModule } from 'ngx-super-croppie';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { PhotoGuidelinesModalComponent } from './photo-guidelines-modal/photo-guidelines-modal.component';
import { ImageUploadModalComponent } from './image-upload-modal/image-upload-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { DocumentReuploadComponent } from './document-reupload/document-reupload.component';
import { DocumentsUploadHistoryModalComponent } from './documents-upload-history-modal/documents-upload-history-modal.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ImageTestComponent } from './image-test/image-test.component';
import { NgxCaptchaModule } from '@binssoft/ngx-captcha';
import { CaptchaComponent } from './captcha/captcha.component';
import { CODDataComparisonComponent } from './coddata-comparison/coddata-comparison.component';
import { CodRequestModelComponent } from './cod-request-model/cod-request-model.component';
import { BlockCopyPasteDirective } from './validators/block-copy-paste-directive';
import { Tags } from './common/tags';
import { MatMenuModule } from '@angular/material/menu';
import { IdentifyValueChangeDirective } from './validators/identify-value-change-directive';
import { ContactlessResponseComponent } from './contactless-reponse/contactless-response.component';
import { ContactlessPaymentComponent } from './contactless-payment/contactless-payment.component';

const tags = new Tags();

const ngxUiLoaderDefaultConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.ballSpinClockwise, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  pbColor: "green",
  blur: 10,
  fgsColor: "green",
  masterLoaderId: "master",
};

const defaultTooltipOptions: TooltipOptions = {
  'show-delay': 500
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    PageNotFoundComponent,
    ApplicationAddEditComponent,
    ApplicationViewComponent,
    ConfirmationModalComponent,
    NumberOnlyDirective,
    SearchApplicationComponent,
    PaymentComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    PaymentReceiptComponent,
    FocusDirective,
    DateOfBirthDirective,
    FirstFocusDirective,
    CODRequestComponent,
    AlphaNumericOnlyDirective,
    NameDirective,
    BlockCopyPasteDirective,
    DateOfBirthInputDirective,
    HintDirective,
    AddressDirective,
    NameWithNumberDirective,
    NoticeModalComponent,
    ValidatePhoneDirective,
    InformationModalComponent,
    NinVerificationComponent,
    FileUploadModalComponent,
    PhotoGuidelinesModalComponent,
    ImageUploadModalComponent,
    FilePreviewModalComponent,
    DocumentReuploadComponent,
    DocumentsUploadHistoryModalComponent,
    ImageTestComponent,
    CaptchaComponent,
    CODDataComparisonComponent,
    CodRequestModelComponent,
    IdentifyValueChangeDirective,
    ContactlessResponseComponent,
    ContactlessPaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderDefaultConfig),
    NgxUiLoaderRouterModule,
    TooltipModule.forRoot(defaultTooltipOptions as TooltipOptions),
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    FlatpickrModule.forRoot({ dateFormat: "d/m/Y", allowInput: true, clickOpens: false }),
    HttpClientModule,
    SimpleModalModule,
    NgxBarcodeModule,
    NgxCaptchaModule,
    PDFExportModule,
    Ng2TelInputModule,
    NgxDialogsModule,
    NgxSuperCroppieModule,
    WebcamModule,
    PdfViewerModule,
    PerfectScrollbarModule,
    MatMenuModule
  ],
  providers: [
    LoaderService,
    NgxSuperCroppieModule,
    { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: 'false' } },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
