import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[validate-phone]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ValidatePhoneDirective, multi: true }]
})
export class ValidatePhoneDirective implements Validator {
    @Input('validate-phone') phone: string[] = [];

    validate(phoneFieldObject: AbstractControl): ValidationErrors {
        if (!phoneFieldObject) {
          return null;
        }
        
        if (this.isValidValue(phoneFieldObject.value) === false) {
            return null;
        }

        const phoneFieldValue = phoneFieldObject.value;
        
        if(new RegExp(/^0*$/).test(phoneFieldValue) === true) {
            const phoneFieldLabel = this.phone[1];
            phoneFieldObject.markAsDirty();
            return { hasError: true, errorMessage: phoneFieldLabel + ' should be valid number!'};
        }
        
        return null;
    }

    private isValidValue(givenValue: any) : boolean {
        if (givenValue === undefined || givenValue === null  || givenValue === '' ) {
            return false;
        }
        return true;
    }

}
