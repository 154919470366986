<app-header></app-header>

<div class="banner-img-name"></div>

<main class="body_section">
    <section class="main">
    <h3 class="main-heading">{{pageTitle}}</h3>
        <div class="form_main">

            <fieldset style="margin: 0px;">

                <div class="step_info"><span>Application Search</span></div>

                <div class=" form-card" style="padding: 0;">
                    <form #searchApplicationForm="ngForm" (ngSubmit)="searchApplicationForm.form.valid && captchaComponent.verifyCaptcha()" [validateDateOfBirth]="['dateOfBirthDay', 'dateOfBirthMonth', 'dateOfBirthYear', '', constants.FALSE]" autocomplete="off" id="" appFocus>
                        <div class="modal-body">
                            <div class="centered">
                                <details style="float:none;">
                                    <summary>Instructions for Applicant </summary>

                                    <div class="content upload_inst">
                                        <ng-template [ngTemplateOutlet]="instructions"></ng-template>    
                                    </div>
                                </details>
                                <div class="search_grid">
                                    <div class="grid-box">
                                        <div class="form_box">
                                            <label class="fieldlabels">Reference Number <span class="medatory">*</span></label>
                                            <div class="input_box">
                                                <input type="text" name="referenceNumber" hint numberOnly [(ngModel)]="searchApplicationFormObject.referenceNumber" #referenceNumber="ngModel" (focusout)="openRedirectionMessageModal();" class="effect" required="required" autocomplete="off" maxlength="15" firstFocus>
                                                <span class="tooltip"><span class='tooltipArrow'></span>Enter your application reference number.</span>
                                                <span class="focus-border "><i></i></span>
                                            </div>
                                            <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && referenceNumber.invalid">
                                                <span *ngIf="referenceNumber.errors.required">
                                                    Reference Number is required!
                                                </span>
                                            </span>
                                        </div>

                                        <div class="form_box">
                                            <label class="fieldlabels">Search By <span class="medatory">*</span></label>
                                            <div class="input_box">
                                                <select name="searchBy" [(ngModel)]="searchApplicationFormObject.searchBy" #searchBy="ngModel" (ngModelChange)="searchApplicationFormObject.searchValue = constants.BLANK" class="effect" required="required" >
                                                    <option value="">Choose Option</option>
                                                    <option *ngFor="let searchByObjectKey of searchByParamListObjectKeys(searchByParamListObject)" [value]="searchByObjectKey">{{searchByParamListObject[searchByObjectKey]}}</option>
                                                </select>
                                                <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                                <span class="tooltip"><span class='tooltipArrow'></span></span>
                                                <span class="focus-border "><i></i></span>
                                            </div>
                                            <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && searchBy.invalid">
                                                <span *ngIf="searchBy.errors.required">
                                                    Search By is required!
                                                </span>
                                            </span>
                                        </div>

                                        <div *ngIf="utils.isEmpty(searchApplicationFormObject.searchBy) === false">

                                            <div *ngIf="searchApplicationFormObject.searchBy === constants.APPLICATION_ID" class="form_box">
                                                <label class="fieldlabels">Application Number <span class="medatory">*</span></label>
                                                <div class="input_box">
                                                    <input type="text" name="searchValue" hint numberOnly [(ngModel)]="searchApplicationFormObject.searchValue" #searchValue="ngModel" class="effect" required="required" autocomplete="off"  maxlength="12">
                                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your application number.</span>
                                                    <span class="focus-border "><i></i></span>
                                                </div>
                                                <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && searchValue.invalid">
                                                    <span *ngIf="searchValue.errors.required">
                                                        Application Number is required!
                                                    </span>
                                                </span>
                                            </div>

                                            <div *ngIf="searchApplicationFormObject.searchBy === constants.EMAIL_ID" class="form_box">
                                                <label class="fieldlabels">Email<span class="medatory">*</span></label>
                                                <div class="input_box">
                                                    <input type="text" name="searchValue" hint [(ngModel)]="searchApplicationFormObject.searchValue" #searchValue="ngModel" class="effect" required="required" autocomplete="off"  maxlength="100" [pattern]="pattern.EMAIL_FIELD_PATTERN">
                                                    <span class="tooltip"><span class='tooltipArrow'></span>Please provide a valid email address in the format yourname@example.com.</span>
                                                    <span class="focus-border "><i></i></span>
                                                </div>
                                                <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && searchValue.invalid">
                                                    <span *ngIf="searchValue.errors.required">
                                                        Email address is required!
                                                    </span>
                                                    <span *ngIf="searchValue.errors.pattern">
                                                        Email address should be valid email!
                                                    </span>
                                                </span>
                                            </div>

                                            <div *ngIf="searchApplicationFormObject.searchBy === constants.DATE_OF_BIRTH" class="form_box">
                                                <label class="fieldlabels">Date of Birth (dd/mm/yyyy) <span class="medatory">*</span></label>
                                                <div class="grid-date">
                                                    <div class="input_box">
                                                        <input type="text" name="dateOfBirthDay" hint numberOnly [(ngModel)]="searchApplicationFormObject.dateOfBirthDay" #dateOfBirthDay="ngModel" class="effect" required="required" autocomplete="off"  maxlength="2">
                                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the date on which you were born. This should be matched with your NIN document.</span>
                                                        <span class="focus-border "><i></i></span>
                                                    </div>

                                                    <div class="input_box">
                                                        <select name="dateOfBirthMonth" hint [(ngModel)]="searchApplicationFormObject.dateOfBirthMonth" #dateOfBirthMonth="ngModel" class="effect" required="required" autocomplete="off" >
                                                            <option *ngFor="let object of monthsList;" [value]="object.value">{{object.text}}</option>
                                                        </select>
                                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose the month in which you were born. This should be matched with your NIN document.</span>
                                                        <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                                        <span class="focus-border "><i></i></span>
                                                    </div>

                                                    <div class="input_box">
                                                        <input type="text" name="dateOfBirthYear" hint numberOnly [(ngModel)]="searchApplicationFormObject.dateOfBirthYear" #dateOfBirthYear="ngModel" class="effect" required="required" autocomplete="off" minlength="4" maxlength="4">
                                                        <span class="tooltip"><span class='tooltipArrow'></span>Please enter the year in which you were born. This should be matched with your NIN document.</span>
                                                        <span class="focus-border "><i></i></span>
                                                    </div>
                                                </div>
                                                <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && (dateOfBirthDay.invalid || dateOfBirthMonth.invalid || dateOfBirthYear.invalid)">
                                                    <span *ngIf="(dateOfBirthDay.errors?.required || dateOfBirthMonth.errors?.required || dateOfBirthYear.errors?.required)">
                                                        Date of Birth is required!
                                                    </span>
                                                    <span *ngIf="dateOfBirthYear.errors?.minlength">
                                                        Date of Birth's year should be 4 digits!
                                                    </span>
                                                    <span *ngIf="!dateOfBirthYear.errors?.minlength && dateOfBirthDay.errors?.hasError">
                                                        {{dateOfBirthDay.errors.errorMessage}}
                                                    </span>
                                                </span>
                                            </div>

                                        </div>

                                        <div class="captcha mt5 pb10">
                                            <app-captcha [currentForm]="searchApplicationForm" (callbackFunction)="searchApplication()" ></app-captcha>
                                        </div>

                                        <input type="button" (click)="gui.openHomePage()" class="back_btn" value="Back" />
                                        <input type="submit" value="Search" class="save_btn">

                                    </div>

                                    <div class="grid-box">
                                        <div  class="imp_info mt20">
                                            <div  class="element none_dis">
                                                <h4>Important Instructions</h4>

                                                <div class="upload_inst">
                                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </fieldset>

        </div>
    </section>
</main>

<footer class="">
    <div class="main-footer">
        <div class="footer_flex">
            <p>Copyright © {{latestYear}} | All rights reserved.</p>
            <img src="assets/images/newworks-logo.png" >
        </div>
   </div>
</footer>


<ng-template #instructions>

    <ul>
        <li>You can search the application by entering the reference number and either the application number, date of birth or E-mail.</li>
        <li>The reference number and application number are provided after the application form is submitted and the same is shared on your E-mail.</li> 
        <!-- <li>Welcome to the latest version of the Immigration Portal, if you have paid your application on/before 27th Nov 2021, please <a [href]="oldApplicationRedirectURL" style="color: orange; text-decoration: underline;">click here</a> to visit the Old Portal.</li> -->
    </ul>

</ng-template>