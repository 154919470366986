<div class="confirmbox-container">

    <div class="modal-header">
        <h4>Information</h4>
    </div>

    <form #informationForm="ngForm" (ngSubmit)="informationForm.form.valid && confirm()" name="informationForm" id="msform" autocomplete="off">

        <div class="modal-body">

            <p [innerHTML]="message"></p>

            <ng-container *ngIf="isShowCheckBox === true">
                <label class="t_and_c pb10 pointer">
                    <input type="checkbox" name="isTCSelected" [(ngModel)]="isTCSelected" #isConditionSelect="ngModel" required="required"> 
                    <span style="padding-left:7px;">{{checkBoxLabel}}</span>
                    <span class="alert alert-danger" role="alert" *ngIf="informationForm.submitted && isConditionSelect.invalid">
                        <span *ngIf="isConditionSelect.errors.required">
                            Confirmation is required
                        </span>
                    </span>
                </label>
            </ng-container>
        </div>
        
        <div class="modal-footer">
            <button class="btn" style="min-width: 60px;">OK</button>
        </div>

    </form>

</div>