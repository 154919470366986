import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Constants } from '../common/constants';

@Component({
  selector: 'app-cod-request-model',
  templateUrl: './cod-request-model.component.html',
  styleUrls: ['./cod-request-model.component.css']
})

export class CodRequestModelComponent extends SimpleModalComponent<null, boolean> {

  currentTab: string = this.constants.MARRIAGE;

  constructor(public constants: Constants) {
    super();
  }

  cancel(): void {
    this.result = true;
    this.close();
  }

}
