export const environment = {

  production: false,

  projectBuildDate: '31/07/2024',

  //http://185.3.95.28:8080/ngnPassport/
  serverBaseUrl: 'https://passport.swglobalstaging.com:8443/ngnPassport/',
  // serverBaseUrl: 'https://ppt.swglobalstaging.com:8443/ngnPassport/',
  //serverBaseUrl: 'https://staging.newworkssolution.com:8443/ngnPassport/',
  esbUrl: 'https://esb.swglobalstaging.com/esb/api/',

  appointmentApplicationUrl: 'https://apt-stage.newworkssolution.com/',

  passportPremiumServiceUrl: 'https://apt.oisservices.com/',

  passportPremiumServiceInfoUrl: 'https://immigration.gov.ng/passport-premium-service/',

  trackApplicationUrl: 'http://track.immigration.gov.ng/',

  usdPassportApplicationUrl: 'https://staging.newworkssolution.com/passusd/',

  oldNgnPassportApplicationSearchUrl: 'https://portal.immigration.gov.ng/passport/passportPaymentStatus',

  oldNgnPassportApplicationGuarantorUrl: 'https://portal.immigration.gov.ng/passport/passportGuarantor',

  centreAvailabilityUrl: 'https://apt-stage.newworkssolution.com/availability/',

  backOfficePassportNGNAdminUrl: 'http://192.168.1.3:4200/',

  enablePassportDeliveryStage: true,

  passportDeliveryTermsLink: 'https://bit.ly/gwx-terms-and-conditions'

};


