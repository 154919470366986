import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[address-directive]'
})
export class AddressDirective {
  
    constructor() { }

    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('keydown', ['$event'])
    public onKeydownHandler(e: KeyboardEvent): void {
        if(new RegExp(/^([0-9a-zA-Z., -/\\]+s?)*$/).test(e.key) === false){
            e.preventDefault();
        }
    }

}