import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup } from '@angular/forms';
import { ValidateDateOfBirth } from './validator-service';

@Directive({
    selector: '[validateDateOfBirth]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateOfBirthDirective, multi: true }]
})
export class DateOfBirthDirective implements Validator {
    @Input('validateDateOfBirth') dateOfBirth: string[] = [];

    validate(formGroup: FormGroup): ValidationErrors {
        return ValidateDateOfBirth(this.dateOfBirth[0], this.dateOfBirth[1], this.dateOfBirth[2], this.dateOfBirth[3], this.dateOfBirth[4])(formGroup);
    }
}