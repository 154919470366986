import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { environment } from 'src/environments/environment';
import { Route } from '../common/route';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  private encryptedApplicantId: string = this.constants.BLANK;

  private encryptedPaymentType: string = this.constants.BLANK;

  applicantId: string = this.constants.BLANK;

  private paymentType: string = this.constants.BLANK;


  paymentFormObject: any = {};
  contactlessStatus: boolean = false;

  applicationPaymentDetailsObject: any = {};

  iopPaymentMethodsArray: any = [];

  sanaPayPaymentMethodsArray: any = [];

  paystackPaymentMethodsArray: any = [];

  teasyPaymentMethodsArray: any = [];

  unifiedPaymentMethodsArray: any = [];

  gatewayDetailsList: any = [];

  isPayButtonDisabled: boolean = false;

  isEnableIOPGateway: boolean = false;

  isEnableSanaPayGateway: boolean = false;

  isEnablePaystackGateway: boolean = false;

  isEnableTeasyGateway: boolean = false;

  isEnableUnifiedGateway: boolean = false;

  referenceLabel: string = "Reference Number";

  private isSubmitForm: number = this.constants.FALSE;

  private paymentPostForm: HTMLFormElement;

  constructor(private router: Router, private route: Route, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, public constants: Constants,
    private loaderService: LoaderService, private toast: Toast, public utils: Utils, private localStorage: LocalStorage, private tags: Tags, private gui: GUI) {
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params['applicantId'];
      this.encryptedPaymentType = params['paymentType'];
    });
    this.applicantId = this.utils.decryptString(this.encryptedApplicantId);
    this.paymentType = this.utils.decryptString(this.encryptedPaymentType);
    this.storeApplicationPaymentDetailsV2();
    this.getApplicationInfo();
  }

  ngOnInit(): void {
    this.paymentPostForm = document.forms['paymentForm'];
  }

  ngOnDestroy(): void {
    this.clearApplicationInfo();
  }

  private storeApplicationPaymentDetailsV2(): void {
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationPaymentDetailsV2(this.applicantId, this.constants.APPLICATION_PAYMENT).then(serverResponse => {
      console.log("server response", serverResponse);
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        const serverFeeChangedMsg = serverResponse.feeChangedMsg;
        if (this.utils.isEmpty(serverFeeChangedMsg) === false) {
          this.isPayButtonDisabled = true;
          this.toast.showErrorToastWithCallbackFunction(serverFeeChangedMsg, this.openApplicationAddEditPage.bind(this, this.ngZone, this.router, this.encryptedApplicantId));
          return;
        }
        this.applicationPaymentDetailsObject = serverResponse;
        this.applicationPaymentDetailsObject.transactionFee = 0;
        this.applicationPaymentDetailsObject.applicationFee += (this.contactlessStatus ? 50000 : 0);
        this.applicationPaymentDetailsObject.totalFee += (this.contactlessStatus ? 50000 : 0);
        this.applicationPaymentDetailsObject.totalFeeIncludeServiceFee = this.applicationPaymentDetailsObject.totalFee;
        this.gatewayDetailsList = serverResponse.gatewayDetails;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private openApplicationAddEditPage(ngZone: NgZone, router: Router, encryptedApplicantId: string): void {
    ngZone.run(() => router.navigate([this.route.APPLICATION_ADD_EDIT, encryptedApplicantId]));
  }

  fetchTransactionCharges(paymentMethodsArray: any[]): void {
    for (let paymentMethod of paymentMethodsArray) {
      if (this.paymentFormObject.paymentMethod === paymentMethod.key) {
        this.applicationPaymentDetailsObject.transactionFee = paymentMethod.transactionFee;
        this.applicationPaymentDetailsObject.serviceCharge = paymentMethod.serviceCharge;
        this.applicationPaymentDetailsObject.totalFeeIncludeServiceFee = this.applicationPaymentDetailsObject.totalFee + this.applicationPaymentDetailsObject.transactionFee + this.applicationPaymentDetailsObject.serviceCharge;
        this.applicationPaymentDetailsObject.paymentMethodLabel = paymentMethod.label;
        break;
      }
    }
  }

  setTransactionFee(): void {
    for (let gatewayObject of this.gatewayDetailsList) {
      for (let gatewayItemObject of gatewayObject.items) {
        if (gatewayItemObject.paymentMethod === this.paymentFormObject.paymentMethod) {
          this.applicationPaymentDetailsObject.transactionFee = gatewayItemObject.transactionFee;
          this.applicationPaymentDetailsObject.serviceCharge = gatewayItemObject.serviceCharge;
          this.applicationPaymentDetailsObject.totalFeeIncludeServiceFee = this.applicationPaymentDetailsObject.totalFee + gatewayItemObject.transactionFee + gatewayItemObject.serviceCharge;
          this.applicationPaymentDetailsObject.paymentMethodLabel = gatewayItemObject.paymentMethodLabelWithGateway;
          break;
        }
      }
    }
  }

  backToApplicationView(): void {
    if (this.paymentType === this.constants.APPLICATION_PAYMENT) {
      this.gui.openApplicationView(this.applicationPaymentDetailsObject.applicantId, this.constants.KIN_INFO_STAGE);
    } else {
      this.gui.openCODView(this.applicationPaymentDetailsObject.referenceNumber);
    }
  }


  private getApplicationInfo(): void {
    this.contactlessStatus = JSON.parse(localStorage.getItem("contactlessStatus")) || null;
  }

  private clearApplicationInfo(): void {
    localStorage.removeItem("contactlessStatus");
  }

  setIsSubmitForm(isSubmitForm: number): void {
    this.isSubmitForm = isSubmitForm;
  }

  makePayment(): void {
    this.loaderService.startLoader();
    this.isPayButtonDisabled = true;
    if (this.isSubmitForm === this.constants.TRUE) {
      this.localStorage.setStorageKey(this.constants.PAYMENT_TYPE, this.encryptedPaymentType);
      this.paymentPostForm.action = environment.serverBaseUrl + "v1/payments/makePayment";
      this.paymentPostForm.authToken.value = this.localStorage.getStorageKey(this.tags.APPLICATION_TOKEN);
      this.paymentPostForm.submit();
    } else {
      this.paymentFormObject.applicationId = this.applicationPaymentDetailsObject.applicationId;
      this.paymentFormObject.currencyCode = this.applicationPaymentDetailsObject.currencyCode;
      this.paymentFormObject.totalAmount = this.applicationPaymentDetailsObject.totalFee += (this.contactlessStatus ? 50000 : 0);
      this.paymentFormObject.transactionFee = this.applicationPaymentDetailsObject.transactionFee += (this.contactlessStatus ? 50000 : 0);
      this.paymentFormObject.serviceCharge = this.applicationPaymentDetailsObject.serviceCharge;
      this.paymentFormObject.paymentMode = this.paymentFormObject.paymentMethod;
      this.paymentFormObject.paymentType = this.constants.APPLICATION_PAYMENT;
      this.paymentFormObject.passportType = this.applicationPaymentDetailsObject.passportTypeKey;

      this.httpUtils.makeApplicationPayment(this.paymentFormObject).then(serverResponse => {
        this.loaderService.stopLoader();
        const serverResponseCode = serverResponse.responseCode;
        if (serverResponseCode === this.constants.STATUS_SUCCESS) {
          if (this.utils.isEmpty(serverResponse.feeChangedMsg) === false) {
            this.toast.showErrorToast(serverResponse.feeChangedMsg);
          }
          this.gui.openPaymentReceipt(serverResponse.txnRefNumber);
        } else {
          this.toast.showErrorToast(serverResponse.responseMsg);
          this.isPayButtonDisabled = false;
        }
      });
    }
  }

}
