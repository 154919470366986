import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GUI } from '../common/gui';
import { LoaderService } from '../common/loader-service';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';

@Component({
  selector: 'app-contactless-payment',
  templateUrl: './contactless-payment.component.html',
  styleUrls: ['./contactless-payment.component.css']
})
export class ContactlessPaymentComponent implements OnInit {
  applicationId: string | null = null;
  referenceNumber: string | null = null;
  status: number | null = null;
  private encryptedApplicantId: string = this.constants.BLANK;
  @Output('callbackFunction') callbackFunction: EventEmitter<any> = new EventEmitter();

  private applicantId: string = this.constants.BLANK;

  private encryptedRequestResource: string = this.constants.BLANK;

  requestResource: string = this.constants.BLANK;

  private personalDetailsObject: any = {};


  constructor(
    private activatedRoute: ActivatedRoute,
    private httpUtils: HttpUtils,
    public constants: Constants,
    private loaderService: LoaderService,
    public utils: Utils,
    public gui: GUI,
    private localStorage: LocalStorage,
    private tags: Tags,
    private toast: Toast,
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.applicationId = params['applicationId'] || null;
      this.referenceNumber = params['referenceNumber'] || null;
      this.status = params['status'] || null;
    });

  }


  ngOnInit(): void {
    this.getServerToken()
    this.checkApplicationFromEsb();
  }

  private checkApplicationFromEsb(): void {
    this.loaderService.startLoader();

    this.httpUtils.fetchApplicationFromEsb(this.applicationId, this.referenceNumber, this.status).then(serverResponse => {

      if (serverResponse) {
        localStorage.setItem("applicationId", serverResponse?.aId);
        localStorage.setItem("referenceId", serverResponse?.refId);
        localStorage.setItem("contactlessStatus", serverResponse?.contactlessStatus);
        this.applicantId = this.utils.decryptString(serverResponse.applicantId);
        this.storeApplicationViewData();
      }

    })

    this.loaderService.stopLoader();
  }

  private storeApplicationViewData(): void {
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationViewData(this.applicantId, this.requestResource).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if (serverResponseCode === this.constants.STATUS_SUCCESS) {
        this.personalDetailsObject = serverResponse?.personalInfo;
        console.log("application infor respone", this.personalDetailsObject);

        if(this.status == 0){
          if(this.personalDetailsObject?.paymentStatus == 200){
                  // go to view aknowlegdement page
                  this.gui.openApplicationViewV2(this.applicantId);
          }
          if(this.personalDetailsObject?.paymentStatus != 200){
            // go to payment page and pay for booklet only and update backend contactless status
            this.gui.openPaymentPage(this.utils.encryptString(this.personalDetailsObject.applicantId), this.constants.APPLICATION_PAYMENT);
          }
        }
        if(this.status == 1){
          if(this.personalDetailsObject?.paymentStatus == 200){
          // go to payment page and pay for contacless fee only and  update backend contactless status
          this.gui.openPaymentPage(this.utils.encryptString(this.personalDetailsObject.applicantId), this.constants.APPLICATION_PAYMENT);
            }
            if(this.personalDetailsObject?.paymentStatus != 200){
              // Pay contactless, booklet fee and  update backend contactless status
              this.gui.openPaymentPage(this.utils.encryptString(this.personalDetailsObject.applicantId), this.constants.APPLICATION_PAYMENT);
              }
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }




  private getServerToken(): void {
    this.loaderService.startLoader();
  this.httpUtils.fetchServerToken().then(serverResponse => {
    if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
      this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, serverResponse.bearerToken);
      this.callbackFunction.emit();
    }
  });
  this.loaderService.stopLoader();

  }

}
