<header>

  <nav>

    <div class="main header-main" (window:beforeunload)="doBeforeUnload();" (window:unload)="doUnload();" style="padding-bottom:0!important;">

      <div class="header_wrapper">

        <div class="header_box">
            <a href="https://immigration.gov.ng/"><img src="assets/images/logo-nis.png"></a>
            <hr class="header_line">
        </div>

        <span class="nav-txt">Domestic Passport Application Portal</span>

        <div class="header_box menu">
            <div  class="hamburger" (click)="callMobileView()">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
            </div>
            
            <ul  class="nav-links nav-home">
                <li><a routerLink="">Home</a></li>
                <li><a href="javascript:void(0);" (click)="openTrackApplicationForm();" class="track_btn">Track Application</a></li>
            </ul>
        </div>

      </div>

    </div>

  </nav>

</header>
