import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface RequestInterface {
  message: string;
}
@Component({
  selector: 'app-notice-modal',
  templateUrl: './notice-modal.component.html',
  styleUrls: ['./notice-modal.component.css']
})
export class NoticeModalComponent extends SimpleModalComponent<RequestInterface, boolean> implements RequestInterface {
  
  message: string;
  
  constructor() { 
    super();
  }

  confirm(): void {
    this.result = true;
    this.close();
  }

  cancel(): void {
    this.result = false;
    this.close();
  }

}