import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ 
    providedIn: 'root'
})
export class SelectService {

    private selectOptions: any[];
    public selectFilterControl: FormControl = new FormControl();
    public filteredOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    protected _onDestroy = new Subject<void>();
    
    public setSelectOptions(selectOptions: any[]){
        this.selectOptions = selectOptions;
        this.filteredOptions.next(this.selectOptions.slice());
        this.selectFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterOptions();
        });
    }

    private filterOptions() {
        if(!this.selectOptions) {
            return;
        }
        let search = this.selectFilterControl.value;
        if (!search) {
            this.filteredOptions.next(this.selectOptions.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredOptions.next(
            this.selectOptions.filter(option => option.text.toLowerCase().startsWith(search.toLowerCase()))
        );
    }

}