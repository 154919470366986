<div class="image_upload_container image-upload-large">

    <div class="modal-header">

        <h4>{{applicableDocObject.docTypeLabel}}</h4>

        <i class="fa fa-times" aria-hidden="true" (click)="cancel()"></i>

    </div>

    <div class="modal-body view_pdf">

        <ng-container *ngIf="applicableDocObject.showDocSubTypeLabel === true">
            <p class="doc-type">Document Type: {{applicableDocObject.docSubTypeLabel}}</p>
        </ng-container>

        <div *ngIf="isPDFDoc === false" class="doc-data">
            <perfect-scrollbar class="ps-show-always">
                <img [src]="base64Data" />
            </perfect-scrollbar>
        </div>

        <pdf-viewer *ngIf="isPDFDoc === true" [src]="base64Data" [fit-to-page]="true"></pdf-viewer>

    </div>

</div>
