import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { GUI } from '../common/gui';
import { NgForm } from '@angular/forms';
import { CaptchaComponent } from '../captcha/captcha.component';
import { LocalStorage } from '../common/local-storage';

@Component({
  selector: 'app-nin-verification',
  templateUrl: './nin-verification.component.html',
  styleUrls: ['./nin-verification.component.css']
})
export class NinVerificationComponent {

  @ViewChild(CaptchaComponent) captchaComponent : CaptchaComponent;

  @ViewChild('ninVerificationForm') ninVerificationForm : NgForm;

  private encryptedApplyingFor: string = this.constants.BLANK;

  private loggedUserId: string = this.constants.BLANK;

  applyingFor: string = this.constants.BLANK;

  pageTitle: string  = this.constants.BLANK;

  ninVerificationFormObject: any = {};

  ninDetailsObject : any = {};

  monthsList: any = [];

  showNINDetailsSection: boolean = false;

  private isAdminLoggedIn: number = this.constants.FALSE;

  constructor(private activatedRoute: ActivatedRoute, private utils: Utils, public constants: Constants, private httpUtils: HttpUtils, private loaderService: LoaderService, private toast: Toast, private gui: GUI,
              private localStorage: LocalStorage) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplyingFor = params['applyingFor'];
    });
    this.applyingFor = this.utils.decryptString(this.encryptedApplyingFor);
    this.setPageTitle();
    this.setFormDefaultValues();
    this.checkIsAdminLoggedIn();
  }

  private checkIsAdminLoggedIn(): void {
    this.loggedUserId = this.localStorage.getLoggedUserId();
    this.isAdminLoggedIn =  this.utils.isEmpty(this.loggedUserId) === false ? this.constants.TRUE : this.constants.FALSE;
  }

  private setPageTitle(): void {
    if(this.applyingFor === this.constants.FRESH) {
      this.pageTitle = 'NIN Verification for Fresh Passport Application';
    } else if(this.applyingFor === this.constants.RENEW) {
      this.pageTitle = 'NIN Verification for Renewal/Re-issue Passport Application';
    } else if(this.applyingFor === this.constants.REISSUE){
      this.pageTitle = 'NIN Verification for Correction of Data';
    }
  }

  private setFormDefaultValues(): void {
    this.monthsList = this.utils.getMonthsArray();
    this.ninVerificationFormObject.applyingFor = this.applyingFor;
    this.ninVerificationFormObject.dateOfBirthMonth = this.constants.BLANK;
    this.showNINDetailsSection = false;
  }

  verifyCaptcha(): void {
    this.captchaComponent.verifyCaptcha();
  }

  validateNIN(): void {
    this.ninVerificationFormObject.dateOfBirth = this.ninVerificationFormObject.dateOfBirthDay + '/' + this.ninVerificationFormObject.dateOfBirthMonth + '/' + this.ninVerificationFormObject.dateOfBirthYear;

    this.httpUtils.validateNIN(this.ninVerificationFormObject).then(serverResponse =>{
      this.loaderService.stopLoader();
      if(serverResponse.responseCode == this.constants.STATUS_SUCCESS){
        this.ninDetailsObject = serverResponse.details;
        this.showNINDetailsSection = true;
      }else{
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  saveNINDetails(): void {
    this.loaderService.startLoader();
    this.ninVerificationFormObject.isFilledByAdminForCustomer = this.isAdminLoggedIn;
    this.ninVerificationFormObject.loggedUserId = this.loggedUserId;

    this.httpUtils.saveNIN(this.ninVerificationFormObject).then(serverResponse =>{
      this.loaderService.stopLoader();
      if(serverResponse.responseCode == this.constants.STATUS_SUCCESS){
        const masterId = serverResponse.masterId;
        if(this.applyingFor === this.constants.FRESH) {
          this.gui.openApplicationAddEditPage(masterId);
        } else {
          this.gui.openPassportVerificationPage(masterId, this.applyingFor);
        }
      } else{
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  reset(): void {
    this.ninVerificationForm.resetForm();
    this.captchaComponent.resetCaptcha();
    this.setFormDefaultValues();
  }

}
