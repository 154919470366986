import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { GUI } from '../common/gui';
import { SimpleModalService } from 'ngx-simple-modal';
import { CodRequestModelComponent } from '../cod-request-model/cod-request-model.component';
import { NgForm } from '@angular/forms';
import { Pattern } from '../common/pattern';

@Component({
  selector: 'app-codrequest',
  templateUrl: './codrequest.component.html',
  styleUrls: ['./codrequest.component.css']
})
export class CODRequestComponent implements OnInit {

  //======================================================================
  //CHANGES HERE ARE MUST BE APPLIED TO BACKOFFICE_PASSPORT_NGN CODEBASE
  //======================================================================

  @ViewChild('passportDetailsForm') passportDetailsForm : NgForm;

  private encryptedMasterId: string = this.constants.BLANK;

  private encryptedApplyingFor: string = this.constants.BLANK;
  
  private masterId: string = this.constants.BLANK;

  applyingFor: string = this.constants.BLANK;
  
  passportDetailsFormObject: any = {};

  passportDetailsObject: any = {};
  
  monthsList:any = [];

  codComparisonItemsList: any = [];

  showPassportDetailsSection: boolean = false;

  pageTitle: string = this.constants.BLANK;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils, public constants: Constants, 
    private loaderService: LoaderService, private gui: GUI, private simpleModalService: SimpleModalService, public pattern: Pattern) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedMasterId = params['masterId'];
      this.encryptedApplyingFor = params['applyingFor'];
    });
    this.masterId = this.utils.decryptString(this.encryptedMasterId);
    this.applyingFor = this.utils.decryptString(this.encryptedApplyingFor);
    this.pageTitle = this.applyingFor === this.constants.RENEW ? 'Passport Verification for Renewal/Re-issue Passport Application' : 'Passport Verification for Correction of Data'
    this.setPersonalDetailsFormDefaultValues()
  }
  
  ngOnInit(): void {
    window.scrollTo(0,0);
  }

  private setPersonalDetailsFormDefaultValues(): void {
    this.showPassportDetailsSection = false;
    this.monthsList = this.utils.getMonthsArray();
    this.passportDetailsFormObject.masterId = this.masterId;
    this.passportDetailsFormObject.applyingFor = this.applyingFor;
    this.passportDetailsFormObject.currentPassportNumber = this.constants.BLANK;
    this.passportDetailsFormObject.dateOfBirthMonth = this.constants.BLANK;
  }

  saveExistingPassportDetails(): void {
    window.scrollTo(0,0);
    this.loaderService.startLoader();
    this.httpUtils.saveCurrentPassportData(this.passportDetailsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        if(this.applyingFor === this.constants.RENEW) {
          this.gui.openApplicationAddEditPage(this.masterId);
        }else if(this.applyingFor === this.constants.REISSUE) {
          this.gui.openCODDataComparisonPage(this.masterId);
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openCODDocumentsModal(): void {
    this.simpleModalService.addModal(CodRequestModelComponent, null, {autoFocus: true, closeOnEscape: true});
  }

  fetchExistingPassportDetails(): void {
    window.scrollTo(0,0);
    this.loaderService.startLoader();
    this.passportDetailsFormObject.currentDateOfBirth = this.passportDetailsFormObject.dateOfBirthDay + '/' + this.passportDetailsFormObject.dateOfBirthMonth + '/' + this.passportDetailsFormObject.dateOfBirthYear;
    this.httpUtils.fetchCurrentPassportData(this.passportDetailsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        if(this.applyingFor === this.constants.REISSUE){
          this.passportDetailsObject = serverResponse.details;
        }else if(this.applyingFor === this.constants.RENEW) {
          this.codComparisonItemsList = serverResponse.comparisonItems;
        }
        this.showPassportDetailsSection = true;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  reset(): void {
    this.passportDetailsForm.resetForm();
    this.setPersonalDetailsFormDefaultValues();
  }

}