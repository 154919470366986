import { Directive, HostListener, Input } from '@angular/core';
import { Constants } from '../common/constants';

@Directive({
    selector: '[identify-value-change]'
})
export class IdentifyValueChangeDirective {
    @Input('identify-value-change') formJSONObject:any = {};

    private focusCount:number = this.constants.FALSE;

    private lastFocusFieldName:string = this.constants.BLANK;

    private initialFieldValue:string = this.constants.BLANK;

    constructor(private constants: Constants) { }

    @HostListener('focus', ['$event'])
    public onFocusHandler(event: any): void {
        const fieldName = event.target.name;

        if(this.lastFocusFieldName !== fieldName){
            this.lastFocusFieldName = fieldName;
            this.focusCount = this.constants.TRUE;
        }
    
        if(this.focusCount === this.constants.TRUE){
            const fieldType = event.target.type;
            const fieldValue = fieldType === this.constants.RADIO_FIELD || fieldType === this.constants.CHECKBOX_FIELD ? event.target.checked : event.target.value;
            this.initialFieldValue = fieldValue;
        }
            
        this.focusCount++;
    }

    @HostListener('blur', ['$event'])
    public onBlurHandler(event: any): void {
        const fieldType = event.target.type;
        const currentFieldValue = fieldType === this.constants.RADIO_FIELD || fieldType === this.constants.CHECKBOX_FIELD ? event.target.checked : event.target.value;

        if(this.formJSONObject.hasFieldValueChanged === this.constants.TRUE){
            return;
        }

        if(this.initialFieldValue !== currentFieldValue){
            this.formJSONObject.hasFieldValueChanged = this.constants.TRUE;
        }
    }
    
}