<div class="confirmbox-container">
    <div class="modal-header">
        <h4>Confirmation</h4>
    </div>

    <div class="modal-body">
      <p [innerHTML]="message"></p>
    </div>
    
    <div class="modal-footer">
        <button type="button" class="btn" (click)="confirm()" style="min-width: 60px;">OK</button>
        <button type="button" class="back_btn" (click)="cancel()">Cancel</button>
    </div>
</div>